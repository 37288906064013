import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
//import { production_credentials } from './production_credentials';
//import { development_credentials } from './development_credentials';
import { connectFunctionsEmulator } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZtZ93gfqi7eIpe9uHVrfzfLGVXQYG63g",
  authDomain: "ecommerce-marketing-helper.firebaseapp.com",
  projectId: "ecommerce-marketing-helper",
  storageBucket: "ecommerce-marketing-helper.appspot.com",
  messagingSenderId: "905802070071",
  appId: "1:905802070071:web:c26db062fb79c13bdca7b2",
  measurementId: "G-TW92PCPZLC"
};

//const credentials = process.env.NODE_ENV === 'development' ? 
//development_credentials
//: production_credentials;
//const credentials = production_credentials;
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const cloudFunctions = firebase.functions();

//if(process.env.NODE_ENV === 'development')
//connectFunctionsEmulator(cloudFunctions, "localhost", 5001);

export { auth, db, cloudFunctions };