import React, {useEffect, useState} from 'react';
import { ContentFilter, GetIdea, GetSubjectLines, GetRewriteProductDescription, GetEmailNoteFromFounder } from './Generate';
import { openTask, closeTask } from './Navigation';

export default function Page_Home({

}) {

const [selectedSubPage, setSelectedSubPage] = useState("homepage");

if(selectedSubPage == "homepage") {
return (
<>

<div className="main_content page_aitasks">
      <div className="main">
        <div className="main_hero">
          <div>
            <h1>Hi! What can I help you with?</h1>
            <div>I can use AI to help you with lots of marketing tasks, and the more data I have the better. To get the most amount of data from your customers (zero-party data) make sure to use Octane AI.</div>
          </div>
        </div>
        <hr/>
        <br></br>
        <div className="hidden">
          <button type="button" className="btn btn_spaced btn-dark">All</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">Ads</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">Email</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">SMS</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">Website</button>
        </div>
        <br></br><br></br>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          <div className="col mb-4 d-flex align-items-stretch">
            <div className="card">
              <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top" alt="..."/>
              <div className="card-body flex-column d-flex">
                <h5 className="card-title">Quiz Questions And Answers Generator</h5>
                <p className="card-text"></p>
                <a href="#" onClick={() => setSelectedSubPage("task_quiz")} className="btn btn-primary btn_rounded mt-auto">Use this task</a>
              </div>
            </div>
          </div>
          <div className="col mb-4 d-flex align-items-stretch">
            <div className="card">
              <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top" alt="..."/>
              <div className="card-body flex-column d-flex">
                <h5 className="card-title">Email Subject Line Generator</h5>
                <p className="card-text"></p>
                <a href="#" onClick={() => setSelectedSubPage("task_subjectline")} className="btn btn-primary btn_rounded mt-auto">Use this task</a>
              </div>
            </div>
          </div>
          <div className="col mb-4 d-flex align-items-stretch">
            <div className="card">
              <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top" alt="..."/>
              <div className="card-body flex-column d-flex">
                <h5 className="card-title">Make Product Descriptions Better</h5>
                <p className="card-text"></p>
                <a href="#" onClick={() => setSelectedSubPage("task_productdescriptions")} className="btn btn-primary btn_rounded mt-auto">Use this task</a>
              </div>
            </div>
          </div>
          <div className="col mb-4 d-flex align-items-stretch">
            <div className="card">
              <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top" alt="..."/>
              <div className="card-body flex-column d-flex">
                <h5 className="card-title">Note From Founder Email Generator</h5>
                <p className="card-text"></p>
                <a href="#" onClick={() => setSelectedSubPage("task_founderemail")} className="btn btn-primary btn_rounded mt-auto">Use this task</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

</>
);} else if(selectedSubPage == "task_quiz"){

    return (
        <>
        <div className="main_content page_aitasks_quiz page_aitasks_open">
        <div className="main">
            <div className="main_hero">
            <div>
            <a href="#" onClick={() => setSelectedSubPage("homepage")} className="btn btn-light btn_rounded"><i class="bi-arrow-left-circle"></i>  Back to all AI tasks</a>
                <br></br><br></br>
                <h1>Quiz Questions And Answers Generator</h1>
                <div>I can use AI to help you with lots of marketing tasks, and the more data I have the better. To get the most amount of data from your customers (zero-party data) make sure to use Octane AI.</div>
            </div>
            </div>
            <hr/>
            <br></br>
            <div className="row">
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                <form>
                        <div class="mb-3">
                            <label for="form_signin_email" className="form-label">What kind of product do you sell?</label>
                            <input type="text" className="form-control" id="idea_box" placeholder="Example: Hair care"/>
                        </div>
                    </form>
                    <a href="#" onClick={GetIdea} className="btn btn-primary btn_rounded">Generate with AI</a>
                </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                    <div className="result" id="results"></div>
                    <div className="spinner-border loading_results hidden" role="status"></div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </>

);} else if(selectedSubPage == "task_subjectline"){
    return (
        <div className="main_content page_aitasks_emailsubjectline page_aitasks_open">
        <div className="main">
            <div className="main_hero">
            <div>
            <a href="#" onClick={() => setSelectedSubPage("homepage")} className="btn btn-light btn_rounded"><i class="bi-arrow-left-circle"></i>  Back to all AI tasks</a>
                <br></br><br></br>
                <h1>Email Subject Line Generator</h1>
                <div>I can use AI to help you with lots of marketing tasks, and the more data I have the better. To get the most amount of data from your customers (zero-party data) make sure to use Octane AI.</div>
            </div>
            </div>
            <hr/>
            <br></br>
            <div className="row">
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                <form>
                        <div class="mb-3">
                            <label for="form_signin_email" className="form-label">What kind of email do you want to send?</label>
                            <input type="text" className="form-control" id="idea_box_subjectlines" placeholder="Example: Announce a new skin care product for oily skin"/>
                        </div>
                    </form>
                    <a href="#" onClick={GetSubjectLines} className="btn btn-primary btn_rounded">Generate with AI</a>
                </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                    <div className="result" id="results_subjectlines"></div>
                    <div className="spinner-border loading_results_subjectlines hidden" role="status"></div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
);} else if(selectedSubPage == "task_productdescriptions"){
    return (
        <div className="main_content page_aitasks_rewriteproductdescriptions page_aitasks_open">
        <div className="main">
            <div className="main_hero">
            <div>
            <a href="#" onClick={() => setSelectedSubPage("homepage")} className="btn btn-light btn_rounded"><i class="bi-arrow-left-circle"></i>  Back to all AI tasks</a>
                <br></br><br></br>
                <h1>Make Product Descriptions Better</h1>
                <div>I can use AI to help you with lots of marketing tasks, and the more data I have the better. To get the most amount of data from your customers (zero-party data) make sure to use Octane AI.</div>
            </div>
            </div>
            <hr/>
            <br></br>
            <div className="row">
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                <form>
                        <div class="mb-3">
                            <label for="form_signin_email" className="form-label">Write or paste in your current product description:</label>
                            <textarea rows="8" type="text" className="form-control" id="idea_box_rewriteproductdescription" placeholder="Example: It’s all in the name. Miracle Balm is the secret of no-makeup makeup: a wash of soft-focus moisture to perfect and enhance skin. A light-reflecting superproduct that’s as versatile as it is simple to use. Wear Miracle Balm alone or layer on top of foundation for an instant refresh."/>
                        </div>
                    </form>
                    <a href="#" onClick={GetRewriteProductDescription} className="btn btn-primary btn_rounded">Generate with AI</a>
                </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                    <div className="result" id="results_rewriteproductdescription"></div>
                    <div className="spinner-border loading_results_rewriteproductdescription hidden" role="status"></div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    )
} else if(selectedSubPage == "task_founderemail"){
    return(
        <div className="main_content page_aitasks_notefromfounder page_aitasks_open">
        <div className="main">
            <div className="main_hero">
            <div>
            <a href="#" onClick={() => setSelectedSubPage("homepage")} className="btn btn-light btn_rounded"><i class="bi-arrow-left-circle"></i>  Back to all AI tasks</a>
                <br></br><br></br>
                <h1>Note From Founder Email Generator</h1>
                <div>I can use AI to help you with lots of marketing tasks, and the more data I have the better. To get the most amount of data from your customers (zero-party data) make sure to use Octane AI.</div>
            </div>
            </div>
            <hr/>
            <br></br>
            <div className="row">
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                <form>
                        <div class="mb-3">
                            <label className="form-label">Company founder:</label>
                            <input type="text" className="form-control" id="idea_box_email_notefromfounder_from" placeholder="Example: Matt, Co-Founder and CEO"/>
                        </div>
                        <div class="mb-3">
                            <label className="form-label">Company name:</label>
                            <input type="text" className="form-control" id="idea_box_email_notefromfounder_companyname" placeholder="Example: Octane AI"/>
                        </div>
                        <div class="mb-3">
                            <label className="form-label">Date founded:</label>
                            <input type="text" className="form-control" id="idea_box_email_notefromfounder_datefounded" placeholder="Example: May 2016"/>
                        </div> 
                        <div class="mb-3"> 
                            <label className="form-label">Description:</label>
                            <textarea rows="8" type="text" className="form-control" id="idea_box_email_notefromfounder_description" placeholder="Example: Felt like the internet was a static place, only big companies like Facebook were dynamic for each person that used them, wanted to make a technology that could help every business be dynamic, made Octane AI so that businesses could automatically talk to customers and get to know them and dynamically personalize the experience, work with over 1,000 companies and helped them make over $200mil, can see the future of online business changing for the better and personalization is the key."/>
                        </div>  
                    </form>
                    <a href="#" onClick={GetEmailNoteFromFounder} className="btn btn-primary btn_rounded">Generate with AI</a>
                </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="card">
                <div className="card-body">
                    <div className="result" id="results_email_notefromfounder"></div>
                    <div className="spinner-border loading_results_email_notefromfounder hidden" role="status"></div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    )
}
}
