import React from 'react';
import { signIn, signOutNow, createUser, checkInviteCode, updateUserName } from './SigninSignout';

export default function Compenent_Navbar({

}) {
return (
    <>
    <main className="">
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark sidebar">
        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <img src="octie.png" width="100%"/>
            <span className="fs-4 hidden">Octie</span>
        </a>
        <span><center><h4>Octie</h4>Your AI ecommerce marketing assistant</center></span>
        <hr/>
        <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
            <a href="#" className="nav-link active" aria-current="page">
                <i className ="me-2 bi-cpu"></i>
                AI tasks
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-shop"></i>
                Brand profiles
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-chat-heart"></i>
                Get to know me
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-pencil-square"></i>
                Request a feature
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-share"></i>
                Share invites
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-graph-up-arrow"></i>
                Upgrade your store
            </a>
            </li>
        </ul>
        <hr/>
        <div className="dropdown">
            <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" alt="" width="32" height="32" className="rounded-circle me-2"/>
            <div id="displayname"></div>
            </a>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
            <li><a className="dropdown-item" href="#">Settings</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="#" onClick={signOutNow}>Sign out</a></li>
            </ul>
        </div>
        </div>
    </main>  

    
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark hidden">
    <div className="container-fluid">
        <a className="navbar-brand" href="#">
        <img src="octane_circle.png" alt="" width="30" height="30"/>
        </a>
        <a className="navbar-brand" href="#">Octie</a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <a className="nav-link disabled" aria-current="page" href="#">Your AI ecommerce marketing assistant</a>
            </li>
            <li className="nav-item hidden">
            <a className="nav-link active" aria-current="page" href="#">AI Tasks</a>
            </li>
            <li className="nav-item hidden">
            <a className="nav-link" href="#">Brand Profiles</a>
            </li>
            <li className="nav-item hidden">
            <a className="nav-link" href="#">Settings</a>
            </li>
            <li className="nav-item dropdown hidden">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
            </ul>
            </li>
            <li className="nav-item">
            <a className="nav-link disabled hidden">Disabled</a>
            </li>
        </ul>
        <div className="d-flex">
            <button className="btn btn-outline-light hidden" type="submit">
                Beta invites <span className="badge bg-danger">3</span>
            </button>
            <button onClick={signOutNow} className="btn btn-outline-light" type="submit">
                Sign out
            </button>
        </div>
        </div>
    </div>
    </nav>

    </>
);
}
