import React, {useEffect, useState} from 'react';
import { cloudFunctions, db } from '../utils/firebase';
import { collection, getDocs, getDoc, doc, setDoc, addDoc, query, where } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
// just move everything back to the app.js and remove export


export async function CreatePortalLink2() {
  const c_contentfilter = cloudFunctions.httpsCallable("generate-createPortalLink");
  const r_contentfilter = await c_contentfilter({ returnUrl: window.location.origin });
  window.location.assign(r_contentfilter.url);
}

export async function CreatePortalLink() {
  const functionRef = cloudFunctions.httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
  const { data } = await functionRef({ returnUrl: window.location.origin })
  window.location.assign(data.url);
  //const r_contentfilter = await c_contentfilter({ completion_check: completion_check });
}

export async function ContentFilter(completion_check, results_div, the_userid, the_brandid, taskid) {
  
    //Go call the cloudfunction to check 
    const c_contentfilter = cloudFunctions.httpsCallable("generate-contentfilter");
    const r_contentfilter = await c_contentfilter({ completion_check: completion_check });
    const returned_filter_label = r_contentfilter.data.filter_label;
    console.log("The filter is:" + returned_filter_label);
    if (returned_filter_label > 1) {
        //console.log("Bad");
        document.querySelector('.loading_' + results_div).classList.add('hidden');
        var div = document.getElementById(results_div);
        div.innerHTML = "'The text you are trying to generate contained sensitive content, I can not display it.' - Octie";
    } else {
        //console.log("Good");
        document.querySelector('.loading_' + results_div).classList.add('hidden');
        document.getElementById("generated_results_box").classList.remove('hidden');
        console.log("Here: " + completion_check);
        var div = document.getElementById(results_div);

        var str_completion_check = String(completion_check);

        var remove_new_lines_completion_check = str_completion_check.trim();   
        var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
        
        div.innerHTML = final_remove_new_lines_completion_check.split("\n").join("<br />");

        createNewDocument(the_userid, completion_check, the_brandid, taskid);
    }
}



// Save new idea

export async function createNewDocument(the_userid, document_body, brand_profile, taskid) {

    const thetime = new Date().getTime();
    // Add a new document with a generated id.
    const branddocumentRef = await addDoc(collection(db, "documents"), {
      author: the_userid,
      body: document_body,
      brand_profile: brand_profile,
      status: "idea",
      createdAt: new Date(thetime),
      updatedAt: new Date(thetime),
      task: taskid,
    });
    console.log("Document written with ID: ", branddocumentRef.id);

    // Set the ID in a hidden input here
    document.getElementById("saved_results_id").value = branddocumentRef.id;

  }

// END Save new idea

// Save new idea

export async function saveNewDocument() {

    const thetime = new Date().getTime();

    const selectedfolder = document.getElementById("save-option-selected").value;
    const documentid = document.getElementById("saved_results_id").value;
    const savenewdocumentRef = doc(db, 'documents', documentid);
    setDoc(savenewdocumentRef, {
      status: selectedfolder,
      updatedAt: new Date(thetime),
    }, { merge: true });

    document.getElementById("task-save-button").classList.add("hidden");
    document.getElementById("task-saved-button").classList.remove("hidden");
    
    if(selectedfolder == "draft") {

    } else {
      document.getElementById("save-option-selected").value = "draft";
      document.getElementById("save-option-workspace").classList.remove("save-option-selected");
      document.getElementById("save-option-drafts").classList.add("save-option-selected");
    }

}

// END Save new idea

// Handle selecting draft or workspace when saving an idea

export async function selectSaveTypeNewDocument(selectedfolder) {

  document.getElementById("save-option-selected").value = selectedfolder;
  if(selectedfolder == "draft") {
    document.getElementById("save-option-workspace").classList.remove("save-option-selected");
    document.getElementById("save-option-drafts").classList.add("save-option-selected");
  } else {
    document.getElementById("save-option-drafts").classList.remove("save-option-selected");
    document.getElementById("save-option-workspace").classList.add("save-option-selected");
  }
  
}

// END Handle selecting draft or workspace when saving an idea

// Move document between drafts, workspace, and deleted (ideas)
export async function moveDocument(documentid, selectedfolder){

    const thetime = new Date().getTime();

    const savenewdocumentRef = doc(db, 'documents', documentid);
    setDoc(savenewdocumentRef, {
      status: selectedfolder,
      updatedAt: new Date(thetime),
    }, { merge: true });


    document.getElementById("document_addbutton_" + documentid).classList.add("hidden");
    

    if (selectedfolder === "idea"){

      document.getElementById("document_deleting_" + documentid).classList.remove("hidden");
      setTimeout(function() {
        document.getElementById("document_deleting_" + documentid).classList.add("hidden");
        document.getElementById("document_deleted_" + documentid).classList.remove("hidden");
      }, 1500);
  
      setTimeout(function() {
        document.getElementById("document_" + documentid).classList.add("hidden");
      }, 2000);

    } else {

      document.getElementById("document_adding_" + documentid).classList.remove("hidden");
      setTimeout(function() {
        document.getElementById("document_adding_" + documentid).classList.add("hidden");
        document.getElementById("document_added_" + documentid).classList.remove("hidden");
      }, 1500);
  
      setTimeout(function() {
        document.getElementById("document_" + documentid).classList.add("hidden");
      }, 2000);

    }
    
    

    

    //document.getElementById("task-save-button").classList.add("hidden");
    //document.getElementById("task-saved-button").classList.remove("hidden");
}





























export async function GetIdea() {
    var userid = document.getElementById("userid").value;
    var div = document.getElementById('results');
    div.innerHTML = "";
    document.querySelector('.loading_results').classList.remove('hidden');
    let the_topic = document.getElementById('idea_box').value;
    const c = cloudFunctions.httpsCallable("generate-titles");
    const r = await c({ topic: the_topic, userid: userid });
    console.log(r.data);
    ContentFilter(r.data.titles, "results");
  }

export async function GetSubjectLines() {
    var userid = document.getElementById("userid").value;
    var div = document.getElementById('results_subjectlines');
    div.innerHTML = "";
    document.querySelector('.loading_results_subjectlines').classList.remove('hidden');
    let the_topic = document.getElementById('idea_box_subjectlines').value;
    const c = cloudFunctions.httpsCallable("generate-subjectlines");
    const r = await c({ topic: the_topic, userid: userid });
    console.log(r.data);
    ContentFilter(r.data.subjectlines, "results_subjectlines");
  }

export async function GetRewriteProductDescription() {
    var userid = document.getElementById("userid").value;
    var div = document.getElementById('results_rewriteproductdescription');
    div.innerHTML = "";
    document.querySelector('.loading_results_rewriteproductdescription').classList.remove('hidden');
    let the_topic = document.getElementById('idea_box_rewriteproductdescription').value;
    const c = cloudFunctions.httpsCallable("generate-rewriteproductdescription");
    const r = await c({ topic: the_topic, userid: userid });
    console.log(r.data);
    ContentFilter(r.data.productdescription, "results_rewriteproductdescription");
  }

export async function GetEmailNoteFromFounder() {
    var userid = document.getElementById("userid").value;
    var div = document.getElementById('results_email_notefromfounder');
    div.innerHTML = "";
    document.querySelector('.loading_results_email_notefromfounder').classList.remove('hidden');
    let the_from = document.getElementById('idea_box_email_notefromfounder_from').value;
    let the_companyname = document.getElementById('idea_box_email_notefromfounder_companyname').value;
    let the_datefounded = document.getElementById('idea_box_email_notefromfounder_datefounded').value;
    let the_description = document.getElementById('idea_box_email_notefromfounder_description').value;
    const c = cloudFunctions.httpsCallable("generate-email_notefromfounder");
    const r = await c({ from: the_from, companyname: the_companyname, datefounded: the_datefounded, description: the_description, userid: userid });
    console.log(r.data);
    ContentFilter(r.data.email_notefromfounder, "results_email_notefromfounder");
  }