import React, { useState } from 'react'
import { GetCompletion, GetImage } from '../GetCompletion/GetCompletion';
import { saveNewDocument } from '../BuildingBlocks/Generate';

export default function GetTask_UI({
    brand_name, brand_type, brand_values, brand_target_customer, name, inputs, input_types, input_placeholders, prompt, temperature, max_tokens, the_userid, the_brandid, taskid, task_type
}) {
    
    // Create the task form
    let form_html = "";
    for (let i = 0; i < inputs.length; i++) {
        form_html += `<div class="mb-3"><label class="form-label">${inputs[i]}:</label><${input_types[i]} type="text" class="form-control task_input" id="${inputs[i]}" placeholder="${input_placeholders[i]}" ${inputs[i] === "Brand name" ? "value='" + brand_name + "'" : inputs[i] === "Target customer" ? "value='" + brand_target_customer + "'" : inputs[i] === "Brand type" ? "value='" + brand_type + "'" : ''}>${inputs[i] === "Brand values" ? brand_values : ""}</${input_types[i]}></div>`;
    }

   
    
    return (
        <>
        <input type="hidden" id="prompt_memory"/>
        <input type="hidden" id="saved_results_id"/>
        <div className="row">
            <div className="col-sm-6 task-form">
                <div className="card">
                    <div className="card-body">
                        <form id="task_form" dangerouslySetInnerHTML={{__html: form_html}}></form>
                        <a href="#" onClick={() => GetCompletion(inputs, prompt, temperature, max_tokens, the_userid, the_brandid, taskid, task_type)} className="btn btn-primary btn_rounded">Generate with AI</a>
                    </div>
                </div>
            </div>
            

            <div className="col-sm-6 hidden" id="generated_results_box">
                <div className="card">
                    <div class="card-header">Written by Octie.ai</div>
                    <div className="card-body">
                        <div className="result" id="results"></div>
                    </div>
                    <div className="card-footer text-muted">
                        <a href="#" id="task-save-button" data-bs-toggle="modal" data-bs-target="#savedocumentModal" className="text-decoration-none"><i class="me-2 bi bi-heart"></i>Save</a>
                        <span id="task-saved-button" className="text-decoration-none hidden"><i class="me-2 bi bi-heart-fill"></i>Saved</span>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 hidden" id="generated_image_box">
                <div className="card">
                    <div class="card-header">Image generated by Octie.ai</div>
                    <img src="" id="theimage" width="100%"></img>
                    <div className="card-footer text-muted hidden">
                        <a href="#" id="task-save-button" data-bs-toggle="modal" data-bs-target="#savedocumentModal" className="text-decoration-none"><i class="me-2 bi bi-heart"></i>Save</a>
                        <span id="task-saved-button" className="text-decoration-none hidden"><i class="me-2 bi bi-heart-fill"></i>Saved</span>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 loading_results hidden" id="loading_results">
                <div className="card">
                    <div className="card-body">
                        <div className="spinner-border spinner-border-sm" role="status"></div> <span className="text-muted">Octie is writing... (this may take a few seconds)</span>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 loading_results hidden" id="loading_image_results">
                <div className="card">
                    <div className="card-body">
                        <div className="spinner-border spinner-border-sm" role="status"></div> <span className="text-muted">Octie is making an image that nobody has ever seen before... (this may take 5-10 seconds)</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 text-secondary">
                <hr></hr>
                <div className="pt-2 pb-2">Tip: Want to increase sales by marketing <i>even</i> better? Launch an <a href="https://octaneai.com" target="_blank">Octane AI</a> quiz.</div>
            </div>

        </div>       
        
        </>
    )
      
}