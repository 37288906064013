import React from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail } from "firebase/auth";
import { collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, orderBy } from 'firebase/firestore';
import { db, cloudFunctions } from '../utils/firebase';

//Sign in
export async function signIn() {
    const auth = getAuth();
    // Login info
    let email = document.getElementById('form_signin_email').value;
    let password = document.getElementById('form_signin_password').value;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        alert("Your email and password do not match.")
        const errorCode = error.code;
        const errorMessage = error.message;
      });
}

// Create user doc
const createUserProfile = async(the_userid, name, first_name, last_name) => {

  var invitecode_original = document.getElementById('form_signup_invitecode').value;
  var invitecode = invitecode_original.toLowerCase();
  
  await setDoc(doc(db, "users", the_userid), {
      status: "new",
      invite: invitecode,
      first_name: first_name,
      last_name: last_name,
      name: name,
  }, { merge: true }).then(() => {
    document.getElementById("signupModal").classList.add("hidden");
    document.body.classList.remove("modal-open");
    window.location.reload(); 
  });

}
// END Create user doc

// Create notification that account was created
const notification_UserAccountCreated = async(the_userid, email, first_name, last_name) => {
  
  const thetime = new Date().getTime();
  await setDoc(doc(db, "notifications_users_account_created", the_userid), {
      event: "Created an Account",
      first_name: first_name,
      last_name: last_name,
      email: email,
      createdAt: new Date(thetime),
  }, { merge: true });

}
// END Create notification that account was created

// Update user name
export async function updateUserName(name, id, first_name, last_name) {
    const auth = getAuth();
    updateProfile(auth.currentUser, {
      displayName: name
    }).then(() => {
      // Profile updated!

      // CREATE THE USER DOC HERE SOMEHOW AND THEN RELOAD PAGE AFFFTTEEER
      createUserProfile(id, name, first_name, last_name);
      
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }

// Create new user
export async function createUser() {
    const auth = getAuth();
    
    let first_name = document.getElementById('form_signup_first_name').value;
    let last_name = document.getElementById('form_signup_last_name').value;
    let full_name = first_name + " " + last_name;
    let email = document.getElementById('form_signup_email').value;
    let password = document.getElementById('form_signup_password').value;
  
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      //let username = document.getElementById('form_signup_name').value;
      notification_UserAccountCreated(user.uid, email, first_name, last_name);
      updateUserName(full_name, user.uid, first_name, last_name);
      
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
}
  
export async function checkInviteCode() {
    let invitecode = document.getElementById('form_signup_invitecode').value;
    let password = document.getElementById('form_signup_password').value;
    if (password.length < 6) {
      alert("Your password must be at least 6 characters long, please choose a longer password.")
      
    } else {
      createUser();
      //alert("That invite code is not valid or has already been used.");
    }
  }

//Sign out
export async function signOutNow() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  //Reset password
  export async function resetPassword(){
    let email = document.getElementById('reset_email').value;
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
      // Password reset email sent!
      alert("Password reset email sent!");
      // ..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
  }
  