import React from 'react';

export default function Compenent_Signededout_Homepage({

}) {
return (
    <>
    <div className="logged_out hidden">
      <div className="black_background">
        <div className="section_center_hero home_page_splash text-white">
          <img src="octie.png" width="150px;"/>
          <br></br><br></br>
          <h1 className="text-octie-orange hidden">Meet Octie<span className="font-weight-light">, your A.I. powered ecommerce marketing assistant.<br></br>Try for free.</span></h1>
          <h1 className="text-octie-orange">Meet Octie<span className="font-weight-light"> - Write marketing copy 10x faster and generate images with AI.</span></h1>
          <br></br>
          <p>Tell Octie what kind of marketing copy you want to write, and Octie will use artificial intelligence to write you amazing emails, text messages, product descriptions, and more.</p>
          <p>✨ <b>NEW: Generate marketing images with AI</b> ✨</p>
          <br></br>
          <button type="button" className="btn btn-light btn-lg home-page-button" data-bs-toggle="modal" data-bs-target="#signupModal">Try risk-free</button><br></br><br></br>
          <button type="button" className=" btn btn-outline-light btn-lg home-page-button" data-bs-toggle="modal" data-bs-target="#signinModal">Sign in</button>
          <br></br>
          <br></br>
          <p><i>Used by 9,000+ marketers • Created by <a href="https://octaneai.com" target="_blank" className="text-white">Octane AI</a></i></p>
        </div>
        <div>
          <div className="section_center_sub home_page_splash">
            <h4 className="text-white"><center>Generate any image you can think of (copyright free!)</center></h4>
            <br></br><br></br>
            <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3">
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                <img src="images/christmas.jpg"/>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                <img src="images/skincream.jpg"/>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                <img src="images/waterdrops.jpg"/>
                </div>
              </div>
            </div>
            <br></br><br></br>
            <h4 className="text-white"><center>Like... anything you want</center></h4>
            <br></br><br></br>
            <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3">
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                <img src="images/dog.jpg"/>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                <img src="images/drawnwoman.jpg"/>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                <img src="images/japan.jpg"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section_center_sub_bg">
          <div className="section_center_sub home_page_splash">
            <h4 className="text-white"><center>Use artificial intelligence to write your ecommerce marketing copy in seconds</center></h4>
            <br></br><br></br>
            <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3">
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Text Message Campaign Copy</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Email Campaign Copy</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Ideas for Non Sales Emails</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Product Description Copy</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Email Subject Lines</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Blog Post Ideas</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Influencer Email Outreach Copy</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Note From Founder Email copy</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
              <div className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill bg-dark text-light">
                    <div className="card-body flex-column d-flex">
                      <h5 className="card-title">Quiz Questions and Answers</h5>
                      <p className="card-text"></p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section_center_hero home_page_splash text-white">
          <br></br><br></br>
          <h1 className="text-octie-orange">Start saving time with Octie today.</h1>
          <br></br>
          <button type="button" className="btn btn-light btn-lg" data-bs-toggle="modal" data-bs-target="#signupModal">Try risk-free</button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div className="bg-black footer-text"><center><a href="https://www.octaneai.com/terms" target="_blank" className="text-decoration-none text-secondary">Terms</a> • <a href="https://www.octaneai.com/privacy" target="_blank" className="text-decoration-none text-secondary">Privacy Policy</a></center></div>
      </div>
    </div>
    </>
);
}
