import React, {useEffect, useState} from 'react';
import { cloudFunctions } from '../utils/firebase';
import { ContentFilter } from '../BuildingBlocks/Generate';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";



export async function GetCompletion2(inputs, prompt, temperature, max_tokens, the_userid, the_brandid, taskid) {
    
  document.getElementById("task-save-button").classList.remove("hidden");
  document.getElementById("task-saved-button").classList.add("hidden");

  var the_prompt = prompt;

  var getinputs = document.getElementById('task_form').getElementsByClassName('task_input');
  var answers = [];

  for (var i = 0; i < getinputs.length; i++) {
    var prompt_memory = the_prompt;
    the_prompt = prompt_memory;
    answers.push(getinputs[i].value);
    //alert("${" + inputs[i] + "}");
    prompt_memory = prompt_memory.replace("${" + inputs[i] + "}", getinputs[i].value);
    the_prompt = prompt_memory;
  }
  
  // Take the answers and replace each placeholder with the correct item from the answers array, use a for loop

  // console.log(prompt_memory);

  var userid = document.getElementById("userid").value;
  var div = document.getElementById('results');
  div.innerHTML = "";
  document.getElementById("generated_results_box").classList.add('hidden');
  document.querySelector('.loading_results').classList.remove('hidden');

  const c = cloudFunctions.httpsCallable("generate-completions");
  const r = await c({ prompt: prompt_memory, temperature: temperature, max_tokens: max_tokens, userid: userid });
  console.log(r.data);
  ContentFilter(r.data.results, "results", the_userid, the_brandid, taskid);
}
    
//

export async function GetImage() {
  alert("Starting...");
  const c = cloudFunctions.httpsCallable("generate-imagegenerate");
  const r = await c({ prompt: "ultra detailed fantasy of maasai warriorin futuristic armor, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5" });
  console.log(r.data.results);
  //alert(r.data.results);
  var image = document.getElementById("theimage");
  image.src = r.data.results;
}

export async function GetCompletion(inputs, prompt, temperature, max_tokens, the_userid, the_brandid, taskid, task_type) {
  
  if(task_type === "image") {
    
    document.getElementById("task-save-button").classList.remove("hidden");
    document.getElementById("task-saved-button").classList.add("hidden");

    var the_prompt = prompt;

    var getinputs = document.getElementById('task_form').getElementsByClassName('task_input');
    var answers = [];

    for (var i = 0; i < getinputs.length; i++) {
      var prompt_memory = the_prompt;
      the_prompt = prompt_memory;
      answers.push(getinputs[i].value);
      //alert("${" + inputs[i] + "}");
      prompt_memory = prompt_memory.replace("${" + inputs[i] + "}", getinputs[i].value);
      the_prompt = prompt_memory;
    }
    
    // Take the answers and replace each placeholder with the correct item from the answers array, use a for loop

    var userid = document.getElementById("userid").value;
    var div = document.getElementById('results');
    div.innerHTML = "";
    document.getElementById("generated_image_box").classList.add('hidden');
    document.getElementById('loading_image_results').classList.remove('hidden');

    const c = cloudFunctions.httpsCallable("generate-imagegenerate");
    const r = await c({ prompt: prompt_memory, userid: userid });
    console.log(r.data.results);
    var image = document.getElementById("theimage");
    image.src = r.data.results;

    document.getElementById('loading_image_results').classList.add('hidden');
    document.getElementById("generated_image_box").classList.remove('hidden');
    
  } else {
    document.getElementById("task-save-button").classList.remove("hidden");
    document.getElementById("task-saved-button").classList.add("hidden");

    var the_prompt = prompt;

    var getinputs = document.getElementById('task_form').getElementsByClassName('task_input');
    var answers = [];

    for (var i = 0; i < getinputs.length; i++) {
      var prompt_memory = the_prompt;
      the_prompt = prompt_memory;
      answers.push(getinputs[i].value);
      //alert("${" + inputs[i] + "}");
      prompt_memory = prompt_memory.replace("${" + inputs[i] + "}", getinputs[i].value);
      the_prompt = prompt_memory;
    }
    
    // Take the answers and replace each placeholder with the correct item from the answers array, use a for loop


    var userid = document.getElementById("userid").value;
    var div = document.getElementById('results');
    div.innerHTML = "";
    document.getElementById("generated_results_box").classList.add('hidden');
    document.querySelector('.loading_results').classList.remove('hidden');

    const c = cloudFunctions.httpsCallable("generate-completions");
    const r = await c({ prompt: prompt_memory, temperature: temperature, max_tokens: max_tokens, userid: userid });
    console.log(r.data);
    ContentFilter(r.data.results, "results", the_userid, the_brandid, taskid);
  }
}