import React from 'react';
import { signIn, signOutNow, createUser, checkInviteCode, updateUserName, resetPassword } from './SigninSignout';
import { saveNewDocument, selectSaveTypeNewDocument } from './Generate';

export default function Modals({

}) {
return (
<>

<div className="modal fade" id="signinModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Sign in to Octie</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form>
            <div class="mb-3">
                <label for="form_signin_email" className="form-label">Email address</label>
                <input type="email" className="form-control" id="form_signin_email" aria-describedby="emailHelp"/>
            </div>
            <div class="mb-3">
                <label for="form_signin_password" className="form-label">Password</label>
                <input type="password" className="form-control" id="form_signin_password"/>
            </div>
        </form>
      </div>
      <div className="modal-footer">
        <p><a class="text-decoration-none" href="#" data-bs-toggle="modal" data-bs-target="#resetpasswordModal">Forgot your password?</a></p>
        <button onClick={signIn} type="button" className="btn btn-primary" data-bs-dismiss="modal">Next</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="signupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Sign up</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form className="row">
            <div class="mb-3 col-md-6">
                <label for="exampleInputEmail1" className="form-label">First name</label>
                <input type="text" className="form-control" id="form_signup_first_name" aria-describedby="First name"/>
            </div>
            <div class="mb-3 col-md-6">
                <label for="exampleInputEmail1" className="form-label">Last name</label>
                <input type="text" className="form-control" id="form_signup_last_name" aria-describedby="Last name"/>
            </div>
            <div class="mb-3">
                <label for="exampleInputEmail1" className="form-label">Email address</label>
                <input type="email" className="form-control" id="form_signup_email" aria-describedby="Email address"/>
            </div>
            <div class="mb-3">
                <label for="form_signin_password" className="form-label">Password</label>
                <input type="password" className="form-control" id="form_signup_password"/>
            </div>
            <div class="mb-3 hidden">
                <label for="exampleInputEmail1" className="form-label">Invite code (Optional)</label>
                <input type="text" className="form-control" id="form_signup_invitecode" aria-describedby="Invite code"/>
            </div>
        </form>
        <p className="text-muted">*By signing up for Octie you will periodically receive emails related to the platform.</p>
      </div>
      <div className="modal-footer">
        <button onClick={checkInviteCode} type="button" className="btn btn-primary">Next</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="resetpasswordModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Reset your password</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form>
            <div class="mb-3">
                <label for="reset_email" className="form-label">Email address</label>
                <input type="email" className="form-control" id="reset_email" aria-describedby="emailHelp"/>
            </div>
        </form>
      </div>
      <div className="modal-footer">
        <button onClick={resetPassword} type="button" className="btn btn-primary" data-bs-dismiss="modal">Reset password</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="savedocumentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Save to</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <input type="hidden" id="save-option-selected" value="draft" />
      <div className="save-option save-option-selected" id="save-option-drafts" onClick={() => selectSaveTypeNewDocument("draft")}>
        <div>Drafts</div>
        <small class="form-text text-muted">Only you can see it.</small>
      </div>
      <div className="save-option" id="save-option-workspace" onClick={() => selectSaveTypeNewDocument("workspace")}>
        <div>Workspace</div>
        <small class="form-text text-muted">Your team can see it.</small>
      </div>
      <div className="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" onClick={saveNewDocument} className="btn btn-primary" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="shareModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Octie.ai - It's 100% free</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <p>Please share Octie.ai with your friends, colleagues, or anyone else you think can benefit from it. <b>Just send them the link!</b></p>
        <form>
            <div class="mb-3">
                <textarea rows="1" className="form-control" aria-describedby="emailHelp">https://octie.ai</textarea>
            </div>
        </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

</>
);
}
