import React, {useEffect, useState} from 'react';
import { ContentFilter, GetIdea, GetSubjectLines, GetRewriteProductDescription, GetEmailNoteFromFounder, moveDocument, CreatePortalLink } from './Generate';
import { openTask, closeTask } from './Navigation';
import GetTask from '../GetTask/GetTask';
import GetBrandProfile from '../GetBrandProfile/GetBrandProfile';
import { db, cloudFunctions } from '../utils/firebase';
import { collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { signIn, signOutNow, createUser, checkInviteCode, updateUserName } from './SigninSignout';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/compat/app';


export default function Tasks2({
    the_userid, the_useremail, the_username
}) {


    

    // Generate task list 
    const [tasks, setTasks] = useState('');

    const tasks_arr = [];
    
    useEffect(() => {
        getTaskList();
        console.log('i fire once');
    },[])


    // Checks if you have a user id and if you do then it checks if you have created a brand already
    useEffect(() => { if (the_userid !== 'notset') { getUserStatus(); getCloud_selected_brand_profile(); checkForInvitesExisting();} }, [the_userid]);
    

    // Takes you to the home page or to the create new brand page depending on your account status

    const [getUserCode, setGetUserCode] = useState('');

    const getUserStatus = async() => {
        // check new user
        const docRef = doc(db, "users", the_userid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            
            // THIS CODE WORKS!!!! Could detect if something like "invite" was mentioned and then show a different screen
            // const url = window.location.href;
            // if (url.includes('about')) { setSelectedSubPage("homepage") }
            // else {setSelectedSubPage("ideas");}
            setGetUserCode(docSnap.data().invite);

            if (docSnap.data().status === "new" && docSnap.data().selected_brand_profile == null || docSnap.data().status === "invited") {
                // You have signed up but you don't have an approved account yet, lets figure out what to do with you next
                checkApplicationStatus();
            } else if (docSnap.data().status === "new" && docSnap.data().selected_brand_profile != null) {
                // You have signed up and you do have an approved account, go to the dashboard
                checkForSubscription();
                
            } else if (docSnap.data().status === "approved" && docSnap.data().selected_brand_profile == null) {
                // You have signed up and you do have an approved account, go to the dashboard
                setSelectedSubPage("new_team");
                
            } else if (docSnap.data().status === "approved" && docSnap.data().selected_brand_profile != null) {
                //setSelectedSubPage("homepage");
                // CHECK FOR SUBSCRIPTION HERE
                checkForSubscription();
            } else {
                createUserProfile();
            }

        } else {
            
            // Create a user document
            createUserProfile();
            
        }
        // end check new user
    }

    // END Checks if you have a user id and if you do then it checks if you have created a brand already

    // Check if they have an active subscription

    const checkForSubscription = async() => {
        // Check customers/the_userid/subscriptions to see if any exist
        const subscriptions_arr = [];
        const q = query(collection(db, "customers", the_userid, "subscriptions"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            
            const subscription_end_time = (doc.data().current_period_end.seconds * 1000);
            const todays_time = (new Date().getTime());

            if (subscription_end_time > todays_time) {
                // There is still time left on the plan, no matter if it is active or canceled
                subscriptions_arr.push({id: doc.id, current_period_end: doc.data().current_period_end.seconds});
            }

        });
        if (subscriptions_arr.length < 1) {
            // There are no subscriptions or subscription days left, take them to the subscriptions page!
            setSelectedSubPage("subscribe");
            // setTimeout(function() {
            //     // do something
            //     setSelectedSubPage("subscribe");
            // }, 25000);
            //setSelectedSubPage("homepage");
        } else {
            // There are subscription days available, let them in!
            setSelectedSubPage("homepage");
        }
        const thetime = new Date().getTime();
        //alert(thetime);
        const todaytime =  new Date(thetime);
        //alert(todaytime);

    }

    // END Check if they have an active subscription

    // Create user profile if it doesn't exist

    const createUserProfile = async() => {
        // Add a new document in collection "users"
        await setDoc(doc(db, "users", the_userid), {
            status: "new",
        }, { merge: true });
        getUserStatus();
    }

    // END Create user profile if it doesn't exist

    // Check if they started an application

    const checkApplicationStatus = async() => {
        setSelectedSubPage("new_user_signup_intro");
        const docRef = doc(db, "users_applications", the_userid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            if (docSnap.data().status === "started") {

                document.getElementById("new_user_application").classList.remove("hidden");
                
            } else if(docSnap.data().status === "submitted") {
                
                document.getElementById("new_user_waitlist").classList.remove("hidden");
            }
        } else {

            // Start the octi intro
            document.getElementById("new_user_intro_to_octie").classList.remove("hidden");

        }
    }

    // END Check if they started an application

    // Get last selected brand profile ID from cloud

    const [cloud_selected_brand_profile, setCloud_selected_brand_profile] = useState('');

    const getCloud_selected_brand_profile = async() => {

        const docRef = doc(db, "users", the_userid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            
            setCloud_selected_brand_profile(docSnap.data().selected_brand_profile);
            
            getBrandProfiles(docSnap.data().selected_brand_profile);
            //getBrandIdeas(docSnap.data().selected_brand_profile);
            
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

    }

    // END Get last selected brand profile ID from cloud

    // Update selected brand profile ID in the cloud

    const updateCloudSelectedBrandProfile = async(cloudbrandid) => {

        const cloudbrandprofileRef = doc(db, 'users', the_userid);
        setDoc(cloudbrandprofileRef, { 
            selected_brand_profile: cloudbrandid,
        }, { merge: true });
        //setBrand_profiles('');
        brand_profiles_arr.length = 0;
        getCloud_selected_brand_profile();
        switchTeams(cloudbrandid);
        
        //Opens the ideas page with documents from the right brand id
        //SelectPage_Ideas(cloudbrandid);
        SelectHomepage(cloudbrandid);

        //Sets the ID so that new saved idea documents are tagged with the right brand id
        setCloud_selected_brand_profile(cloudbrandid);
     
    }

    // END Update selected brand profile ID in the cloud

 

    const getTaskList = async(allbutton) => {
        
        const q = query(collection(db, "tasks"), orderBy("featured", "asc"));
        const querySnapshot = await getDocs(q);

        
        //const querySnapshot = await getDocs(collection(db, "tasks"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            
            //setTasks([...tasks, doc.data()]);
            //setTasks([...tasks, {name: doc.data().name}]);
            console.log(doc.data());
            tasks_arr.push({id: doc.id, name: doc.data().name, category: doc.data().category});
            
        });
        console.log("Tasks: " + tasks_arr);


        // MAKE A FUNCTION THAT AUTO GENERATES BASED ON THE TASK ID
        const final_tasks_arr = tasks_arr.map((task) =>
            <>
            <div key={task.id} id={task.id} className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill task-card">
                <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top hidden" alt="..."/>
                <div className="card-body flex-column d-flex">
                    <h5 className="card-title">{task.name}</h5>
                    <p className="card-text hidden"></p>
                    <a href="#" onClick={() => SelectTask(task.id)} className="btn btn-primary btn_rounded mt-auto hidden">Use this task</a>
                    <a href="#" onClick={() => SelectTask(task.id)} className="hidden mt-auto stretched-link no_decoration_links">Use this task <i class="bi bi-arrow-right-circle"></i></a>
                    <a href="#" onClick={() => SelectTask(task.id)} className="mt-auto stretched-link no_decoration_links"><span className="badge bg-light text-dark rounded-pill font-weight-light">{task.category}</span></a>
                </div>
                </div>
            </div>
            </>
        );
        setTasks(final_tasks_arr);
       
    }

    const getTaskListAllCategory = async(allbutton) => {

        document.querySelectorAll('.category_selector').forEach(function(element) { element.classList.remove('btn-dark'); });
        document.querySelectorAll('.category_selector').forEach(function(element) { element.classList.add('btn-outline-secondary'); });
        document.getElementById('category_all').classList.remove('btn-outline-secondary');
        document.getElementById('category_all').classList.add('btn-dark');
        
        const q = query(collection(db, "tasks"), orderBy("featured", "asc"));
        const querySnapshot = await getDocs(q);

        
        //const querySnapshot = await getDocs(collection(db, "tasks"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            
            //setTasks([...tasks, doc.data()]);
            //setTasks([...tasks, {name: doc.data().name}]);
            console.log(doc.data());
            tasks_arr.push({id: doc.id, name: doc.data().name, category: doc.data().category});
            
        });
        console.log("Tasks: " + tasks_arr);


        // MAKE A FUNCTION THAT AUTO GENERATES BASED ON THE TASK ID
        const final_tasks_arr = tasks_arr.map((task) =>
            <>
            <div key={task.id} id={task.id} className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill task-card">
                <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top hidden" alt="..."/>
                <div className="card-body flex-column d-flex">
                    <h5 className="card-title">{task.name}</h5>
                    <p className="card-text hidden"></p>
                    <a href="#" onClick={() => SelectTask(task.id)} className="btn btn-primary btn_rounded mt-auto hidden">Use this task</a>
                    <a href="#" onClick={() => SelectTask(task.id)} className="mt-auto stretched-link no_decoration_links"><span className="badge bg-light text-dark rounded-pill font-weight-light">{task.category}</span></a>
                    
                </div>
                </div>
            </div>
            </>
        );
        setTasks(final_tasks_arr);
       
    }

    const getTaskListCategory = async(category, categoryClass) => {
        
        document.querySelectorAll('.category_selector').forEach(function(element) { element.classList.remove('btn-dark'); });
        document.querySelectorAll('.category_selector').forEach(function(element) { element.classList.add('btn-outline-secondary'); });
        document.getElementById('category_' + categoryClass).classList.remove('btn-outline-secondary');
        document.getElementById('category_' + categoryClass).classList.add('btn-dark');

        const q = query(collection(db, "tasks"), orderBy("featured", "asc"), where("category", "==", category));
        const querySnapshot = await getDocs(q);

        
        //const querySnapshot = await getDocs(collection(db, "tasks"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            
            //setTasks([...tasks, doc.data()]);
            //setTasks([...tasks, {name: doc.data().name}]);
            console.log(doc.data());
            tasks_arr.push({id: doc.id, name: doc.data().name, category: doc.data().category});
            
        });
        console.log("Tasks: " + tasks_arr);


        // MAKE A FUNCTION THAT AUTO GENERATES BASED ON THE TASK ID
        const final_tasks_arr = tasks_arr.map((task) =>
            <>
            <div key={task.id} id={task.id} className="col mb-4 d-flex align-items-stretch">
                <div className="card flex-fill task-card">
                <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top hidden" alt="..."/>
                <div className="card-body flex-column d-flex">
                    <h5 className="card-title">{task.name}</h5>
                    <p className="card-text hidden"></p>
                    <a href="#" onClick={() => SelectTask(task.id)} className="btn btn-primary btn_rounded mt-auto hidden">Use this task</a>
                    <a href="#" onClick={() => SelectTask(task.id)} className="mt-auto stretched-link no_decoration_links"><span className="badge bg-light text-dark rounded-pill font-weight-light">{task.category}</span></a>
                </div>
                </div>
            </div>
            </>
        );
        setTasks(final_tasks_arr);
       
    }

    // END Generate task list

    // Generate Brand Profiles for nav

    const [brand_profiles, setBrand_profiles] = useState('');

    const brand_profiles_arr = [];


    const getBrandProfiles = async(cloud_brandprofile_id) => {
        const q = query(collection(db, "brand_profiles"), where("roles." + the_userid,"in", ["owner", "writer", "reader"]));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            
            //setTasks([...tasks, doc.data()]);
            //setTasks([...tasks, {name: doc.data().name}]);
            console.log(doc.data());
            if (cloud_brandprofile_id === doc.id){
                brand_profiles_arr.push({cloudselected: "yes", id: doc.id, name: doc.data().name, type: doc.data().type, target_customer: doc.data().target_customer, values: doc.data().values, website: doc.data().website});
            } else {
                brand_profiles_arr.push({cloudselected: "no", id: doc.id, name: doc.data().name, type: doc.data().type, target_customer: doc.data().target_customer, values: doc.data().values, website: doc.data().website});
            }
        });
        console.log("Brand profiles: " + brand_profiles_arr);


        // MAKE A FUNCTION THAT AUTO GENERATES BASED ON THE TASK ID
        const final_brand_profiles_arr = brand_profiles_arr.map((brand_profile) =>
            <>
            <li>
            <a href="#" onClick={() => updateCloudSelectedBrandProfile(brand_profile.id)} className={brand_profile.cloudselected === 'yes' ? 'main_left_nav mainnav_' + brand_profile.id + ' nav-link text-dark' : 'main_left_nav mainnav_' + brand_profile.id + ' nav-link text-dark'}>
                <i className={brand_profile.cloudselected === 'yes' ? 'main_left_nav_icon me-2 bi-square-fill icon-blue' : 'main_left_nav_icon me-2 bi-square'}></i>
                <b>{brand_profile.name}</b>
            </a>
            </li>
            <li onClick={() => SelectHomepage(brand_profile.id)} className={brand_profile.cloudselected === 'yes' ? 'subnav_create subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_active' :'subnav_create subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive hidden'}>Create</li>
            <li onClick={() => SelectPage_Ideas(brand_profile.id)} className={brand_profile.cloudselected === 'yes' ? 'subnav_ideas subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive' :'subnav_ideas subnav_' + brand_profile.id + ' sub_left_nav hidden'}>Drafts</li>
            <li onClick={() => SelectPage_Workspace(brand_profile.id)} className={brand_profile.cloudselected === 'yes' ? 'subnav_workspace subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive' :'subnav_workspace subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive hidden'}>Workspace</li>
            <li onClick={() => SelectPage_Team(brand_profile.id)} className={brand_profile.cloudselected === 'yes' ? 'subnav_team subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive' :'subnav_team subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive hidden'}>Team</li>
            <li onClick={() => SelectBrandProfile(brand_profile.id)} className={brand_profile.cloudselected === 'yes' ? 'subnav_settings subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive' :'subnav_settings subnav_' + brand_profile.id + ' sub_left_nav sub_left_nav_notactive hidden'}>Settings</li>
            
            </>
        );
        setBrand_profiles(final_brand_profiles_arr);
    
    }
    
    // END Generate Brand Profiles for nav

    // Generate ideas feed

    const [brand_ideas, setBrand_ideas] = useState('');

    const brand_ideas_arr = [];


    const getBrandIdeas2 = async(cloud_brandprofile_id) => {
        setBrand_ideas('');
        brand_ideas_arr.length = 0;
        const q = query(collection(db, "documents"), orderBy("updatedAt", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            console.log(doc.data());

            // Reformat the body

            var str_completion_check = String(doc.data().body);
            var remove_new_lines_completion_check = str_completion_check.trim();   
            var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
            var the_body = final_remove_new_lines_completion_check.split("\n").join("<br />");

            // END reformat the body

            var div = document.getElementById("ideas");
            //div.innerHTML += "<div class='col mb-4 d-flex align-items-stretch'><div class='card flex-fill'><div class='card-body flex-column d-flex'><p class='card-text result'>" + the_body + "</p><div class='supertest' id='" + doc.id + "'>I AM A BUTTON</div></div></div></div>";

            var updatedAt = doc.data().updatedAt.toDate().toDateString();

            brand_ideas_arr.push({id: doc.id, task: doc.data().task, author: doc.data().author, body: the_body, brand_profile: doc.data().brand_profile, createdAt: doc.data().createdAt, updatedAt: updatedAt, status: doc.data().status});
        
        });
        console.log("Ideas: " + brand_ideas_arr);

        const final_brand_ideas_arr = brand_ideas_arr.map((document) =>
            <>
            <tr id={`document_${document.id}`}>
                    <td>{document.id}</td>
                    <td>{document.task}</td>
                    <td>{document.author}</td>
                    <td>{document.brand_profile}</td>
                    <td>{document.updatedAt}</td>
                    <td>{document.status}</td>
                    <td dangerouslySetInnerHTML={{ __html: document.body }}></td>
            </tr>
            
            </>
        );
        if (brand_ideas_arr.length < 1) {
            document.getElementById("empty_feed_ideas").classList.remove('hidden');
        } else {
            document.getElementById("empty_feed_ideas").classList.add('hidden');
        }
        setBrand_ideas(final_brand_ideas_arr);
    
    }

    const getBrandIdeas = async(cloud_brandprofile_id) => {
        setBrand_ideas('');
        brand_ideas_arr.length = 0;
        const q = query(collection(db, "documents"), where('author', '==', the_userid), where('brand_profile', '==', cloud_brandprofile_id), where("status", '==', 'draft'), orderBy("updatedAt", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            console.log(doc.data());

            // Reformat the body

            var str_completion_check = String(doc.data().body);
            var remove_new_lines_completion_check = str_completion_check.trim();   
            var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
            var the_body = final_remove_new_lines_completion_check.split("\n").join("<br />");

            // END reformat the body

            var div = document.getElementById("ideas");
            //div.innerHTML += "<div class='col mb-4 d-flex align-items-stretch'><div class='card flex-fill'><div class='card-body flex-column d-flex'><p class='card-text result'>" + the_body + "</p><div class='supertest' id='" + doc.id + "'>I AM A BUTTON</div></div></div></div>";

            var updatedAt = doc.data().updatedAt.toDate().toDateString();

            brand_ideas_arr.push({id: doc.id, author: doc.data().author, body: the_body, brand_profile: doc.data().brand_profile, createdAt: doc.data().createdAt, updatedAt: updatedAt, status: doc.data().status});
        
        });
        console.log("Ideas: " + brand_ideas_arr);

        const final_brand_ideas_arr = brand_ideas_arr.map((document) =>
            <>
            <div className="col mb-4 d-flex align-items-stretch" id={`document_${document.id}`}>
                <div className="card flex-fill">
                    <div className="card-header">Updated {document.updatedAt}</div>
                    <div className="card-body flex-column d-flex">
                        <p className="card-text result" dangerouslySetInnerHTML={{ __html: document.body }}></p>
                    </div>
                    <div className="card-footer">
                        <span id={`document_addbutton_${document.id}`}>
                            <a onClick={() => moveDocument(document.id, "workspace")} className="clickable text-decoration-none me-3"><i class="me-2 bi bi-people"></i>Add to workspace</a>
                            <a onClick={() => moveDocument(document.id, "idea")} className="clickable text-decoration-none text-secondary"><i className="me-2 bi bi-trash3"></i >Delete</a>
                        </span>
                        <span id={`document_adding_${document.id}`} className="text-secondary hidden"><div className="me-2 spinner-grow spinner-grow-sm" role="status"></div> Adding to workspace</span>
                        <span id={`document_added_${document.id}`} className="text-success hidden"><i class="me-2 bi bi-check-circle-fill"></i> Added to workspace</span>
                        <span id={`document_deleting_${document.id}`} className="text-secondary hidden"><div className="me-2 spinner-grow spinner-grow-sm" role="status"></div> Deleting</span>
                        <span id={`document_deleted_${document.id}`} className="text-success hidden"><i class="me-2 bi bi-check-circle-fill"></i> Deleted</span>
                    </div>
                </div>
            </div>
            </>
        );
        if (brand_ideas_arr.length < 1) {
            document.getElementById("empty_feed_ideas").classList.remove('hidden');
        } else {
            document.getElementById("empty_feed_ideas").classList.add('hidden');
        }
        setBrand_ideas(final_brand_ideas_arr);
    
    }

    // END Generate ideas feed


    // Generate workspace feed

    const [brand_workspace, setBrand_workspace] = useState('');

    const brand_workspace_arr = [];


    const getBrandWorkspace = async(cloud_brandprofile_id) => {
        setBrand_workspace('');
        brand_workspace_arr.length = 0;
        const q = query(collection(db, "documents"), where('brand_profile', '==', cloud_brandprofile_id), where("status", '==', 'workspace'), orderBy("updatedAt", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            //console.log(doc.data());

            // Reformat the body

            var str_completion_check = String(doc.data().body);
            var remove_new_lines_completion_check = str_completion_check.trim();   
            var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
            var the_body = final_remove_new_lines_completion_check.split("\n").join("<br />");

            // END reformat the body

            var div = document.getElementById("feed_workspace");

            // Grab the user info so it can be added into the brand_workspace_arr array
            // Maybe you can have permission to view user docs for users who have matching brand ids with you
            // END Grab the user info 

            var id = doc.id;
            var author = doc.data().author;
            var brand_profile = doc.data().brand_profile;
            var createdAt = doc.data().createdAt;
            var updatedAt = doc.data().updatedAt.toDate().toDateString();
            var status = doc.data().status;

            brand_workspace_arr.push({name: "someone", id: id, author: author, body: the_body, brand_profile: brand_profile, createdAt: createdAt, updatedAt: updatedAt, status: status });
        
        });
        console.log("Workspace: " + brand_workspace_arr);

        if (brand_workspace_arr.length < 1) {
            document.getElementById("empty_feed_workspace").classList.remove('hidden');
        } else {
            document.getElementById("empty_feed_workspace").classList.add('hidden');
        }

        // Ads the username to all of them, but its pretty slow
        const arrayOfIds = brand_workspace_arr.map(obj => ({ id: obj.id, author: obj.author }));

        for (let i = 0; i < arrayOfIds.length; i++) {

            const docRef = doc(db, "users", arrayOfIds[i].author);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {

                const updateName = brand_workspace_arr.find((user) => user.id === arrayOfIds[i].id);
                updateName.name = docSnap.data().name;

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }

        

        const final_brand_workspace_arr = brand_workspace_arr.map((document) =>
            <>
            <div className="col mb-4 d-flex align-items-stretch" id={`document_${document.id}`}>
                <div className="card flex-fill">
                    <div className="card-header">{document.name} • Updated {document.updatedAt}</div>
                    <div className="card-body flex-column d-flex">
                        <p className="card-text result" id={document.id} dangerouslySetInnerHTML={{ __html: document.body }}></p>
                    </div>
                    <div className="card-footer">
                        <span id={`document_addbutton_${document.id}`}>
                            <a onClick={() => moveDocument(document.id, "draft")} className={document.author === the_userid ? 'clickable text-decoration-none me-3' :'clickable text-decoration-none hidden'}><i className="me-2 bi bi-book-half"></i >Move to drafts</a>
                            <a onClick={() => moveDocument(document.id, "idea")} className="clickable text-decoration-none text-secondary"><i className="me-2 bi bi-trash3"></i >Delete</a>
                        </span>
                        <span id={`document_adding_${document.id}`} className="text-secondary hidden"><div className="me-2 spinner-grow spinner-grow-sm" role="status"></div> Moving to drafts</span>
                        <span id={`document_added_${document.id}`} className="text-success hidden"><i class="me-2 bi bi-check-circle-fill"></i> Moved to drafts</span>
                        <span id={`document_deleting_${document.id}`} className="text-secondary hidden"><div className="me-2 spinner-grow spinner-grow-sm" role="status"></div> Deleting</span>
                        <span id={`document_deleted_${document.id}`} className="text-success hidden"><i class="me-2 bi bi-check-circle-fill"></i> Deleted</span>
                    </div>
                </div>
            </div>
            </>
        );
        
        // Can add a loading thing here

        setBrand_workspace(final_brand_workspace_arr);

        
    
    }

    // END Generate workspace feed


    // Generate team members for team page

    const [brand_team, setBrand_team] = useState('');

    const brand_team_arr = [];


    const getBrandTeam = async(cloud_brandprofile_id) => {
        setBrand_team('');
        
        brand_team_arr.length = 0;

        const docRef = doc(db, "brand_profiles", cloud_brandprofile_id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {

            var team_ids = docSnap.data().roles;
            Object.entries(team_ids).forEach(([key, value]) => {
                //alert(value + ' = ' + key);
                brand_team_arr.push({role: value, id: key });
            });
            document.getElementById("hidden_brand_id").value = cloud_brandprofile_id;
            document.getElementById("hidden_brand_name").value = docSnap.data().name;

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

        console.log("Team: " + brand_team_arr);

        // if (brand_team_arr.length < 1) {
        //     document.getElementById("empty_feed_workspace").classList.remove('hidden');
        // } else {
        //     document.getElementById("empty_feed_workspace").classList.add('hidden');
        // }

        // Ads the username to all of them, but its pretty slow
        const arrayOfIdsTeam = brand_team_arr.map(obj => ({ id: obj.id, role: obj.role }));

        for (let i = 0; i < arrayOfIdsTeam.length; i++) {

            const docRef = doc(db, "users", arrayOfIdsTeam[i].id);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {

                const updateName = brand_team_arr.find((user) => user.id === arrayOfIdsTeam[i].id);
                updateName.name = docSnap.data().name;

                if (arrayOfIdsTeam[i].id === the_userid) {
                    document.getElementById("hidden_user_name").value = docSnap.data().name;
                }

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }

        const final_brand_team_arr = brand_team_arr.map((document) =>
            <>
            <li className="list-group-item d-flex justify-content-between align-items-center">
                {document.name}
                <span className="badge bg-light text-dark rounded-pill">{document.role}</span>
            </li>
            </>
        );
        
        // Can add a loading thing here

        setBrand_team(final_brand_team_arr);

    }

    // END Generate team members for team page

    


    // Check if user was already invited to this brand

    const checkIfUserAlreadyInvited = async() => {

        var brand = document.getElementById("hidden_brand_id").value;
        var to_email_original = document.getElementById('email_invite_recipient').value;
        var to_email = to_email_original.toLowerCase();

        const docRef = doc(db, "brand_profiles", brand);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {

            let data = docSnap.data();
            if (data.hasOwnProperty("invites")) { 
                // The "invites" map exists in the document! 
                if(docSnap.data().invites[to_email]) {
                    alert("Already invited");
                } else {
                    inviteNewUser(brand, to_email);
                }
            } else { 
                // The "invites" map does not exist in the document. 
                inviteNewUser(brand, to_email);
            }
            

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

    }

    // END Check if user was already invited to this brand

    // Send invites

    const inviteNewUser = async(brand, to_email) => {

        var brand_name = document.getElementById("hidden_brand_name").value;
        var from_name = document.getElementById("hidden_user_name").value;
        const thetime = new Date().getTime();

        const brandprofileRef = await addDoc(collection(db, "invites"), {
            brand: brand,
            brand_name: brand_name,
            from: the_userid,
            from_name: from_name,
            status: "sent",
            to_email: to_email,
            metric: "Invited to Team",
            createdAt: new Date(thetime),
        });
        alert("Invite sent!");
        document.getElementById('email_invite_recipient').value = "";
        addInvitedUserEmailToBrand(brand, to_email);

    }

    // END Send invites

    // Add invited user's email to the brand document

    const addInvitedUserEmailToBrand = async(brandprofile_id, to_email) => {
        // Add a new document in collection "users"
        await setDoc(doc(db, "brand_profiles", brandprofile_id), {
            invites: {
                [to_email]: "sent"
            }
        }, { merge: true });
    }

    // END Add invited user's email to the brand document

    

    // New account and profile creation

    const createFirstBrandProfile = async(brand_name) => {

        if (brand_name.length < 1) {
            alert("Make sure to put in your brand's name");
        } else {
            // Add a new document with a generated id.
            const brandprofileRef = await addDoc(collection(db, "brand_profiles"), {
                name: brand_name,
                website: "",
                type: "",
                values: "",
                target_customer: "",
                roles: {
                    [the_userid]: "owner"
                }
            });

            updateUserProfile(brandprofileRef.id);
            getCloud_selected_brand_profile();
            //checkForSubscription();
        }
    }
        
    const createBrandProfile = async() => {

        var new_brandprofile_name = document.getElementById('new_brandprofile_name').value;
        if (new_brandprofile_name.length < 1) {
            alert("Make sure to put in your brand's name");
        } else {
            // Add a new document with a generated id.
            const brandprofileRef = await addDoc(collection(db, "brand_profiles"), {
                name: document.getElementById('new_brandprofile_name').value,
                website: document.getElementById('new_brandprofile_website').value,
                type: document.getElementById('new_brandprofile_type').value,
                values: document.getElementById('new_brandprofile_values').value,
                target_customer: document.getElementById('new_brandprofile_target_customer').value,
                roles: {
                    [the_userid]: "owner"
                }
            });
            console.log("Document written with ID: ", brandprofileRef.id);

            updateUserProfile(brandprofileRef.id);
            getCloud_selected_brand_profile();
        }
    }

    const updateUserProfile = async(newbrandprofile) => {
        // Add a new document in collection "users"
        await setDoc(doc(db, "users", the_userid), {
            selected_brand_profile: newbrandprofile,
        }, { merge: true });
        //setSelectedSubPage("ideas");
        getBrandTeam(newbrandprofile);
        document.getElementById("new_brand_info").classList.add("hidden");
        document.getElementById("new_brand_invites").classList.remove("hidden");
    }

    const skipToDashboard = async() => {
        getCloud_selected_brand_profile();
        //setSelectedSubPage("homepage");
        setSelectedSubPage("subscribe");
    }

    // END New account and profile creation

// New user Octie intro

const answerQuizQuestion = async(oldpage, newpage) => {
    if(newpage === "final") {
        document.getElementById(oldpage).classList.add("hidden");
        checkForInvites();
        // Call new function to check if the user has any invites
    } else {
        document.getElementById(oldpage).classList.add("hidden");
        document.getElementById(newpage).classList.remove("hidden");
    }
    
}

// END New user Octie intro

// Check if new user has been invited to team on Octie

const checkForInvites = async() =>  {

    const invites_arr = [];
    const q = query(collection(db, "invites"), where('to_email', '==', the_useremail));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

        // Add the user to each brand they have an invite for
        addInvitedBrands(doc.data().brand);
        updateSelectedBrandProfileFirstTime(doc.data().brand);
        approveInvitedUser();

        console.log(doc.data());
        invites_arr.push({id: doc.id});
    
    });

    if (invites_arr.length < 1) {
        // There are no invites, so do the application

        document.getElementById("new_user_intro_to_octie").classList.add("hidden");
        document.getElementById("new_user_application").classList.remove("hidden");

        // Create application document with status "started"
        createUserApplication();


    } else {
        // There are invites, add the user to all the brands 
        // alert("there are invites!");
        newNotification("approved", "Account Approved");
        setTimeout(function() {
            getCloud_selected_brand_profile();
            //setSelectedSubPage("homepage");
            // Send them to the subscription page
            checkForSubscription();
         }, 2000);
        
    }

}

const checkForInvitesExisting = async() =>  {
    const invites_arr = [];
    const q = query(collection(db, "invites"), where('to_email', '==', the_useremail));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // Add the user to each brand they have an invite for
        addInvitedBrands(doc.data().brand);
    });
}

const addInvitedBrands = async(brandid) => {
    await setDoc(doc(db, "brand_profiles", brandid), {
        invites: {
            [the_useremail]: "approved"
        },
        roles: {
            [the_userid]: "writer"
        }
    }, { merge: true });
}

const approveInvitedUser = async() => {
     // Update the user document to say approved
     await setDoc(doc(db, "users", the_userid), {
        status: "approved",
    }, { merge: true });
}

const updateSelectedBrandProfileFirstTime = async(cloudbrandid) => {

    const cloudbrandprofileRef = doc(db, 'users', the_userid);
    setDoc(cloudbrandprofileRef, { 
        selected_brand_profile: cloudbrandid,
    }, { merge: true });
    //setBrand_profiles('');

    //Sets the ID so that new saved idea documents are tagged with the right brand id
    setCloud_selected_brand_profile(cloudbrandid);
 
}

// END Check if new user has been invited to a team on Octie

// Check if the new user has been sent a general invite code to Octie

const checkForCodes = async() =>  {
    var brand_name = document.getElementById("application_brand_name").value;
    // if (document.querySelector('input[name="shopify_status"]:checked') === null) {
    if (brand_name.length < 1 || document.querySelector('input[name="shopify_status"]:checked') === null) {
        alert("Make sure to answer all the questions!");
    } else {
        approveUserApplication();
        // USED to approve or waitlist
        // if (getUserCode.length < 1) {
        //     // there is NOT a code in the user doc, add to waitlist
        //     waitlistUserApplication();

        // } else {
        //     // there is a code in the user doc
        //     const docRef = doc(db, "codes", getUserCode);
        //     const docSnap = await getDoc(docRef);
            
        //     if (docSnap.exists()) {
        //         // The code is real, approve them!
        //         approveUserApplication();

        //     } else {
        //         // The code is not real, waitlist them!
        //         waitlistUserApplication();
        //     }

        // }
    }

    // THEN CALL ANOTHER FUNCTION TO SEE IF THAT CODE IS AN ACTUAL CODE

}

// END Check if the new user has been sent a general invite code to Octie

// Create user application

const createUserApplication = async() => {
    const thetime = new Date().getTime();
    await setDoc(doc(db, "users_applications", the_userid), {
        status: "started",
        email: the_useremail,
        name: the_username,
        createdAt: new Date(thetime),
    }, { merge: true });
    
}


// END Create user application

// Update and complete user application

const approveUserApplication = async() => {

    newNotification("approved", "Account Approved");

    var brand_name = document.getElementById("application_brand_name").value;
    var brand_website = document.getElementById("application_brand_website").value;
    var note = document.getElementById("application_note").value;
    var source = document.getElementById("application_source").value;
    var shopify_status = document.querySelector('input[name="shopify_status"]:checked').value;

    const applicationeRef = doc(db, 'users_applications', the_userid);
    setDoc(applicationeRef, { 
        brand_name: brand_name,
        brand_website: brand_website,
        shopify_status: shopify_status,
        note: note,
        source: source,
        status: "approved",
    }, { merge: true });

    // APPROVE THE USER!! UPDATE THE USER DOC TO APPROVED???? CREATE A BRAND DOC??? PROMPT THEM TO INVITE TEAM MEMBERS????

    // Update the user document to say approved
    await setDoc(doc(db, "users", the_userid), {
        status: "approved",
    }, { merge: true });

    document.getElementById("new_user_application").classList.add("hidden");
    
    // Open the new brand profile page
    setSelectedSubPage("new_team");
    createFirstBrandProfile(brand_name);
    

}

// END Update and complete user application

// Create new notification

const newNotification = async(notification, event) => {
    const thetime = new Date().getTime();
    const waitlistRef = doc(db, 'notifications_users_account_' + notification, the_userid);
    setDoc(waitlistRef, { 
        email: the_useremail,
        event: event,
        createdAt: new Date(thetime),
    }, { merge: true });
}

// END Create new notification

// Add the application to the wait list

const waitlistUserApplication = async() => {

    newNotification("waitlist", "Account Waitlisted");

    var brand_name = document.getElementById("application_brand_name").value;
    var brand_website = document.getElementById("application_brand_website").value;
    var note = document.getElementById("application_note").value;
    var shopify_status = document.querySelector('input[name="shopify_status"]:checked').value;

    const applicationeRef = doc(db, 'users_applications', the_userid);
    setDoc(applicationeRef, { 
        brand_name: brand_name,
        brand_website: brand_website,
        shopify_status: shopify_status,
        note: note,
        status: "submitted",
    }, { merge: true });
    document.getElementById("new_user_application").classList.add("hidden");
    document.getElementById("new_user_waitlist").classList.remove("hidden");

}

// END Add the application to the wait list

function createNewTeam(){
    setSelectedSubPage("new_team");
    
    setTimeout(function() {
        document.getElementById("new_brand_info").classList.remove("hidden");
        document.getElementById("new_team_cancel").classList.remove("hidden");
    }, 0);
}

function switchTeams(selectedbrandprofile_id){
    document.querySelectorAll('.sub_left_nav').forEach(function(element) { element.classList.add('hidden'); });
    
    document.querySelectorAll('.main_left_nav_icon').forEach(function(element) { element.classList.remove('bi-square-fill', 'icon-blue'); });
    document.querySelectorAll('.main_left_nav_icon').forEach(function(element) { element.classList.remove('bi-square'); });
    document.querySelectorAll('.main_left_nav_icon').forEach(function(element) { element.classList.add('bi-square'); });
    document.querySelectorAll('.mainnav_' + selectedbrandprofile_id + ' .main_left_nav_icon').forEach(function(element) { element.classList.remove('bi-square'); });
    document.querySelectorAll('.mainnav_' + selectedbrandprofile_id + ' .main_left_nav_icon').forEach(function(element) { element.classList.add('bi-square-fill', 'icon-blue'); });

    document.querySelectorAll('.subnav_' + selectedbrandprofile_id).forEach(function(element) { element.classList.remove('hidden'); });
}

function switchSubNav(selectedPage){
    document.querySelectorAll('.sub_left_nav').forEach(function(element) { element.classList.remove('sub_left_nav_active'); });
    document.querySelectorAll('.sub_left_nav').forEach(function(element) { element.classList.add('sub_left_nav_notactive'); });
    document.querySelectorAll('.subnav_' + selectedPage).forEach(function(element) { element.classList.remove('sub_left_nav_notactive'); });
    document.querySelectorAll('.subnav_' + selectedPage).forEach(function(element) { element.classList.add('sub_left_nav_active'); });
}

function openTeamNav(){
    const boxes = document.querySelectorAll('.sidebar');

    boxes.forEach(box => {
        box.classList.remove("sidebar-mobile");
    });

}

function closeTeamNav(){
    const boxes = document.querySelectorAll('.sidebar');

    boxes.forEach(box => {
        box.classList.add("sidebar-mobile");
    });

}


// Stripe

const sendToCheckout = async(plan) =>  {
    document.getElementById("stripe_loading").classList.remove("hidden");
    addDoc(collection(db, "customers", the_userid, "checkout_sessions"), {
        price: plan,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
    })
    .then((docRef) => {
        const transaction_id = docRef.id;
        //alert(transaction_id);
        onSnapshot(doc(db, "customers", the_userid, "checkout_sessions", transaction_id), (doc) => {
            console.log("Current data: ", doc.data());
            const {error, sessionId} = doc.data();
            if (error) {
                alert('An error occured');
            }
            if (sessionId) {
                async function stripeLoad() {
                    const stripe = await loadStripe(
                        'pk_live_51L5lIkHqLsWsYyQwXbSNDAr8bKiOq3sptocTgxef0I4Uau1VNLHizx6l5ZtpS2b1gCS50x3NI9NAHNQBYqAoJr8k00BPHSpVte'
                    );
                    await stripe.redirectToCheckout({ sessionId });
                }
                stripeLoad();

            }
            
        });
   })
}

// const sendToCustomerPortal = async() =>  {
//         const functionRef = firebase.app()
//             .functions('us-central1')
//             .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
//                 async function redirectToCustomerPortal() {
//                     const { data } = await functionRef({ returnUrl: window.location.origin });
//                     window.location.assign(data.url);
//                 }
//                 redirectToCustomerPortal();       
// }

const sendToCustomerPortal = async() =>  {
    const functionRef = firebase.default
    .functions().httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin })
    window.location.assign(data.url);
}


// END Stripe




const [selectedSubPage, setSelectedSubPage] = useState("");
const [selectedTask, setSelectedTask] = useState("");
const [selectedBrandProfile, setSelectedBrandProfile] = useState("");

function SelectTask(selectedtask_id){
    setSelectedSubPage("task");
    setSelectedTask(selectedtask_id);
}

function SelectBrandProfile(selectedbrandprofile_id){
    setSelectedSubPage("brand_profiles_specific");
    setSelectedBrandProfile(selectedbrandprofile_id);
    switchSubNav("settings");
    closeTeamNav();
}

function SelectBrandProfileList(){
    setSelectedSubPage("brand_profiles");
    getBrandProfiles();
}

function SelectHomepage(selectedbrandprofile_id){
    
    setSelectedSubPage("homepage");
    switchSubNav("create");
    closeTeamNav();
    var cleardiv = document.getElementById("ideas");
    //getTaskList();
    //cleardiv.innerHTML = "";
}

function SelectPage_Ideas(selectedbrandprofile_id){
    setSelectedSubPage("ideas");
    getBrandIdeas(selectedbrandprofile_id);
    switchSubNav("ideas");
    closeTeamNav();
    var cleardiv = document.getElementById("ideas");
    //cleardiv.innerHTML = "";
}

function SelectPage_Workspace(selectedbrandprofile_id){
    setSelectedSubPage("workspace");
    getBrandWorkspace(selectedbrandprofile_id);
    switchSubNav("workspace");
    closeTeamNav();
    var cleardiv = document.getElementById("feed_workspace");
    //cleardiv.innerHTML = "";
}

function SelectPage_Team(selectedbrandprofile_id){
    setSelectedSubPage("team");
    switchSubNav("team");
    getBrandTeam(selectedbrandprofile_id);
    closeTeamNav();
}

if(selectedSubPage == "homepage2") {
return (
<>

<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <div className="container-fluid">
        <a className="navbar-brand" href="#" onClick={SelectHomepage}>
            <img src="octane_circle.png" alt="" width="30" height="30"/>
        </a>
        <a className="navbar-brand" href="#">octie.ai</a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item hidden">
            <a className="nav-link disabled" aria-current="page" href="#">Your AI ecommerce marketing assistant</a>
            </li>
            <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#"><i className ="me-2 bi-cpu"></i>Tasks</a>
            </li>
            <li className="nav-item">
            <a className="nav-link" href="#" onClick={SelectBrandProfileList}><i className ="me-2 bi-shop"></i>Brands</a>
            </li>
            <li className="nav-item hidden">
            <a className="nav-link" href="#">Settings</a>
            </li>
            <li className="nav-item dropdown hidden">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
            </ul>
            </li>
            <li className="nav-item">
            <a className="nav-link disabled hidden">Disabled</a>
            </li>
        </ul>
        <div className="d-flex">
            <button className="btn btn-outline-light hidden" type="submit">
                Beta invites <span className="badge bg-danger">3</span>
            </button>
            <button onClick={signOutNow} className="btn btn-outline-light" type="submit">
                Sign out
            </button>
        </div>
        </div>
    </div>
    </nav>

<div className="main_content page_aitasks">
      <div className="main">
        <div className="main_hero hidden">
          <div>
            <br></br>
            <h1>Hi! What can I help you with?</h1>
            <div>I can use AI to help you with lots of marketing tasks, and the more data I have the better. To get the most amount of data from your customers (zero-party data) make sure to use Octane AI.</div>
          </div>
        </div>

        <div className="main_hero">
          <div>
            <br></br>
            <h3>Tasks</h3>
          </div>
        </div>

        <div className="hidden">
          <button type="button" className="btn btn_spaced btn-dark">All</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">Ads</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">Email</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">SMS</button>
          <button type="button" className="btn btn_spaced btn-outline-secondary">Website</button>
        </div>
        

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
        {tasks}
        </div>
        

      </div>
    </div>

</>
);} else if(selectedSubPage == "task"){
    return(
        <>
        
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark  fixed-top">
        <div className="container-fluid">
            <a className="navbar-brand" href="#" onClick={SelectHomepage}>
                <img src="octane_circle.png" alt="" width="30" height="30"/>
            </a>
            <a className="navbar-brand hidden" href="#">octie.ai</a>
    
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                </li>
            </ul>
            <div className="d-flex">
                <button className="btn btn-outline-light hidden" type="submit">
                    Beta invites <span className="badge bg-danger">3</span>
                </button>
                <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                    Sign out
                </button>
                <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                    Contact us
                </a>
                <span>-</span>
                <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                    Share
                </a>
            </div>
            </div>
        </div>
        </nav>
    
        <main>
        <div className="d-flex flex-column flex-shrink-0 p-3 text-dark bg-white sidebar sidebar-mobile">

            
            <ul className="nav nav-pills flex-column mb-auto">
                {brand_profiles}
                <li>
                    <hr/>
                <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                    <i className ="me-2 bi-plus-circle"></i>
                    Create new team
                </a>
                </li>
                <li >
                    
                    <a href="#" className="nav-link text-dark" onClick={sendToCustomerPortal}>
                        <i className ="me-2 bi-gear"></i>
                        Manage account
                    </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-chat-heart"></i>
                    Get to know me
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-pencil-square"></i>
                    Request a feature
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-share"></i>
                    Share invites
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-graph-up-arrow"></i>
                    Upgrade your store
                </a>
                </li>
            </ul>
            <br></br>
            <div class="card bg-dark text-white">
                <img class="card-img opacity-25" src="jonesroad.png" alt="Card image"/>
                <div class="card-img-overlay">
                    <p class="card-text"><b>How The Fastest-Growing Shopify Store 3Xed Their TikTok Ad Revenue</b></p>
                    <a href="https://www.octaneai.com/customers/jones-road-beauty" target="_blank" type="button" class="btn btn-dark btn-sm stretched-link text-warning">Continue reading</a>
                </div>
            </div>
            </div>
        </main>


        <div className="main_content_right">
            <div className="main">
            <GetTask the_userid={the_userid} taskid={selectedTask} the_brandid={cloud_selected_brand_profile}/>
                
            </div>
        </div>
        </>
        
    )
} else if(selectedSubPage == "brand_profiles"){
    return(
        <>
        
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
            <a className="navbar-brand" href="#" onClick={SelectHomepage}>
                <img src="octane_circle.png" alt="" width="30" height="30"/>
            </a>
            <a className="navbar-brand" href="#" onClick={() => setSelectedSubPage("homepage")}>octie.ai</a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item hidden">
                <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#" onClick={() => setSelectedSubPage("homepage")}><i className ="me-2 bi-cpu"></i>Tasks</a>
                </li>
                <li className="nav-item">
                <a className="nav-link active" href="#"><i className ="me-2 bi-shop"></i>Brands</a>
                </li>
                <li className="nav-item hidden">
                <a className="nav-link" href="#">Settings</a>
                </li>
                <li className="nav-item dropdown hidden">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Dropdown
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
                </li>
                <li className="nav-item">
                <a className="nav-link disabled hidden">Disabled</a>
                </li>
            </ul>
            <div className="d-flex">
                <button className="btn btn-outline-light hidden" type="submit">
                    Beta invites <span className="badge bg-danger">3</span>
                </button>
                <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                    Sign out
                </button>
                <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                    Contact us
                </a>
                <span>-</span>
                <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                    Share
                </a>
            </div>
            </div>
        </div>
        </nav>

    <div className="main_content page_aitasks">
        <div className="main">
            <div className="main_hero">
            <div>
                <br></br>
                <h3>Brand Profiles</h3>
                <div>Use brand profiles to save information about your brand that Octie can use when generating content and ideas.</div>
            </div>
            </div>
            <hr/>
            <br></br>
            <div className="hidden">
            <button type="button" className="btn btn_spaced btn-dark">All</button>
            <button type="button" className="btn btn_spaced btn-outline-secondary">Ads</button>
            <button type="button" className="btn btn_spaced btn-outline-secondary">Email</button>
            <button type="button" className="btn btn_spaced btn-outline-secondary">SMS</button>
            <button type="button" className="btn btn_spaced btn-outline-secondary">Website</button>
            </div>
            

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            {brand_profiles}
            </div>

        </div>
        </div>
    </>
        
    )
} else if(selectedSubPage == "brand_profiles_specific"){
    return(
        <>

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark  fixed-top">
        <div className="container-fluid">
            <a className="navbar-brand" href="#" onClick={SelectHomepage}>
                <img src="octane_circle.png" alt="" width="30" height="30"/>
            </a>
            <a className="navbar-brand hidden" href="#">octie.ai</a>
    
            <button onClick={openTeamNav} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                <i class="bi bi-briefcase"></i> Teams
            </button>
            
            <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                <i class="bi bi-box-arrow-right"></i> Sign out
            </button>

            <button className="navbar-toggler hidden" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                </li>
            </ul>
            <div className="d-flex">
                <button className="btn btn-outline-light hidden" type="submit">
                    Beta invites <span className="badge bg-danger">3</span>
                </button>
                <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                    Sign out
                </button>
                <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                    Contact us
                </a>
                <span>-</span>
                <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                    Share
                </a>
            </div>
            </div>
        </div>
        </nav>
    
        <main>
        <div className="d-flex flex-column flex-shrink-0 p-3 text-dark bg-white sidebar sidebar-mobile">
      
            
            <ul className="nav nav-pills flex-column mb-auto">
                {brand_profiles}
                <li>
                    <hr/>
                <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                    <i className ="me-2 bi-plus-circle"></i>
                    Create new team
                </a>
                </li>
                <li >
                    
                    <a href="#" className="nav-link text-dark" onClick={sendToCustomerPortal}>
                        <i className ="me-2 bi-gear"></i>
                        Manage account
                    </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-chat-heart"></i>
                    Get to know me
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-pencil-square"></i>
                    Request a feature
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-share"></i>
                    Share invites
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-graph-up-arrow"></i>
                    Upgrade your store
                </a>
                </li>
            </ul>
            <br></br>
            <div class="card bg-dark text-white">
                <img class="card-img opacity-25" src="jonesroad.png" alt="Card image"/>
                <div class="card-img-overlay">
                    <p class="card-text"><b>How The Fastest-Growing Shopify Store 3Xed Their TikTok Ad Revenue</b></p>
                    <a href="https://www.octaneai.com/customers/jones-road-beauty" target="_blank" type="button" class="btn btn-dark btn-sm stretched-link text-warning">Continue reading</a>
                </div>
            </div>
            </div>
        </main>


        <div className="main_content_right">
            <div className="main">
            <div className="main_hero">
                  <div>
                    <br></br>
                    <h3>Settings</h3>
                  </div>
            </div>
            <GetBrandProfile brandprofileid={selectedBrandProfile}/>
                
            </div>
        </div>
        </>
    )
} else if(selectedSubPage == "new_team"){
    return(
        <>

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
            <a className="navbar-brand" href="#">
            <img src="octane_circle.png" alt="" width="30" height="30"/>
            </a>
            <a className="navbar-brand hidden" href="#">octie.ai</a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item hidden">
                <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                </li>
            </ul>
            <div className="d-flex">
                <button className="btn btn-outline-light hidden" type="submit">
                    Beta invites <span className="badge bg-danger">3</span>
                </button>
                <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary" type="submit">
                    Sign out
                </button>
            </div>
            </div>
        </div>
        </nav>
     


        <div className="main_content hidden" id="new_brand_info">
            <div className="main main_signup">
                <br></br>
                <h1 className="text-center">Create your team</h1>
                <br></br>
                <p className="text-center">This is the ecommerce brand, or agency, you will be using Octie for.</p>
                <p className="text-center">You will be able to invite your team to join you.</p>
                <br></br>
                <div class="card">
                    <div class="card-body">
                        <form className="row">
                            <div class="mb-3 col-md-6">
                                <label class="form-label">Brand name (required):</label>
                                <input type="text" className="form-control task_input" id="new_brandprofile_name" placeholder="Example: Jones Road" required></input>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="form-label">Website:</label>
                                <input type="text" className="form-control task_input" id="new_brandprofile_website" placeholder="Example: jonesroadbeauty.com"></input>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="form-label">Description of what you sell:</label>
                                <input type="text" className="form-control task_input" id="new_brandprofile_type" placeholder="Example: Clean makeup"></input>
                                <small class="form-text text-muted hidden">Be as descriptive as you can about the type of product you sell. Someone should be able to know exactly what you sell just based off of this (for example if you sell hair care products for men, "Men's Grooming" will not be a good description while "hair care products for men, shampoo and conditioner" would be perfect)</small>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="form-label">Target customer:</label>
                                <input type="text" className="form-control task_input" id="new_brandprofile_target_customer" placeholder="Example: Women of all ages"></input>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Brand values:</label>
                                <input type="text" className="form-control task_input" id="new_brandprofile_values" placeholder="Example: Clean, strategic, high-grade formulations that work on every skin type"></input>
                            </div>
                            
                        </form>
                    </div>
                </div>
                <div className="text-center">
                    <br></br>
                    <a href="#" onClick={() => setSelectedSubPage("homepage")} className="me-2 btn btn-outline-secondary btn_rounded hidden" id="new_team_cancel">Cancel</a>
                    <a href="#" onClick={createBrandProfile} className="btn btn-primary btn_rounded">Continue</a>
                </div>
            </div>
        </div>


        <div className="main_content hidden" id="new_brand_invites">
            <div className="main main_signup">
                <br></br>
                <h1 className="text-center">Invite members of your team to join</h1>
                <p className="text-center">Collaborate and share AI written content with the rest of your team.</p>
                <br></br>
                <div id="empty_feed_workspace" className="col mb-4 d-flex align-items-stretch">
                    <div className="card flex-fill">
                        <div className="card-header">Invite your teammates</div>
                        <div className="card-body flex-column d-flex">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" id="email_invite_recipient" placeholder="Email address..." aria-label="Recipient's email" aria-describedby="button-addon2" />
                                <button onClick={checkIfUserAlreadyInvited} class="btn btn-primary" type="button" id="button-addon2"><i class="me-2 bi bi-send"></i> Send invite</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="text-center">
                    <a href="#" onClick={() => skipToDashboard()} className="btn btn-primary btn_rounded">Continue to dashboard</a>
                </div>

                <input type="hidden" id="hidden_brand_id" />
                <input type="hidden" id="hidden_brand_name" />
                <input type="hidden" id="hidden_user_name" />  

            </div>
        </div>


        </>
    )
} else if(selectedSubPage == "loading"){
    return(
        <>

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
            <a className="navbar-brand" href="#">
            <img src="octane_circle.png" alt="" width="30" height="30"/>
            </a>
            <a className="navbar-brand" href="#" onClick={SelectHomepage}>octie.ai</a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item hidden">
                <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                </li>
            </ul>
            <div className="d-flex">
                <button className="btn btn-outline-light hidden" type="submit">
                    Beta invites <span className="badge bg-danger">3</span>
                </button>
                <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                    Sign out
                </button>
                <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                    Contact us
                </a>
                <span>-</span>
                <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                    Share
                </a>
            </div>
            </div>
        </div>
        </nav>


        <div className="main_content text-center">
            <br></br>
            <br></br>
            <br></br>
            <div>LOADING</div>
        </div>
        </>
    )
} else if (selectedSubPage == "homepage") {
    return (
    <>
    
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark  fixed-top">
        <div className="container-fluid">
            <a className="navbar-brand" href="#" onClick={SelectHomepage}>
                <img src="octane_circle.png" alt="" width="30" height="30"/>
            </a>
            <a className="navbar-brand hidden" href="#">octie.ai</a>

            <button onClick={openTeamNav} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                <i class="bi bi-briefcase"></i> Teams
            </button>
            
            <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                <i class="bi bi-box-arrow-right"></i> Sign out
            </button>

            <button className="navbar-toggler hidden" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                </li>
            </ul>
            <div className="d-flex">
                <button className="btn btn-outline-light hidden" type="submit">
                    Beta invites <span className="badge bg-danger">3</span>
                </button>
                <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                    Sign out
                </button>
                <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                    Contact us
                </a>
                <span>-</span>
                <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                    Share
                </a>
            </div>
            </div>
        </div>
        </nav>
    
    <main>
    <div className="d-flex flex-column flex-shrink-0 p-3 text-dark bg-white sidebar sidebar-mobile">
        
        <ul className="nav nav-pills flex-column mb-auto">
            <li className="mb-2 hidden"><b>Inspiration</b></li>
            <li className="sub_left_nav_notactive hidden">   
                <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                    <i className ="me-2 bi-envelope-heart"></i>
                    Incredible Emails
                </a>
            </li>
            <li className="sub_left_nav_notactive hidden">   
                <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                    <i className ="me-2 bi-chat-heart"></i>
                    Amazing Texts
                </a>
            </li>
            <li className="sub_left_nav_notactive hidden">   
                <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                    <i className ="me-2 bi-chat-heart"></i>
                    Expert Tips & Tricks
                </a>
            </li>
         
            <li className="mb-2 hidden"><b>Teams</b></li>
            {brand_profiles}
            <hr/>
            <li>   
                <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                    <i className ="me-2 bi-plus-circle"></i>
                    Create new team
                </a>
            </li>
            <li >
                    
                <a href="#" className="nav-link text-dark" onClick={sendToCustomerPortal}>
                    <i className ="me-2 bi-gear"></i>
                    Manage account
                </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-chat-heart"></i>
                Get to know me
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-pencil-square"></i>
                Request a feature
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-share"></i>
                Share invites
            </a>
            </li>
            <li className="hidden">
            <a href="#" className="nav-link text-white">
                <i className ="me-2 bi-graph-up-arrow"></i>
                Upgrade your store
            </a>
            </li>
        </ul>
        <br></br>
        <div class="card bg-dark text-white">
                <img class="card-img opacity-25" src="jonesroad.png" alt="Card image"/>
                <div class="card-img-overlay">
                    <p class="card-text"><b>How The Fastest-Growing Shopify Store 3Xed Their TikTok Ad Revenue</b></p>
                    <a href="https://www.octaneai.com/customers/jones-road-beauty" target="_blank" type="button" class="btn btn-dark btn-sm stretched-link text-warning">Continue reading</a>
                </div>
            </div>
        </div>
    </main>
    
    <div className="main_content_right">
          <div className="main">
            <div className="main_hero hidden">
              <div>
                <br></br>
                <h1>Hi! What can I help you with?</h1>
                <div>I can use AI to help you with lots of marketing tasks, and the more data I have the better. To get the most amount of data from your customers (zero-party data) make sure to use Octane AI.</div>
              </div>
            </div>
    
            <div className="main_hero">
              <div>
                <br></br>
                <button onClick={() => setSelectedSubPage("subscribe")} type="button" className="btn btn-outline-primary hidden">Subscribe Page</button>
                <button onClick={() => setSelectedSubPage("unlock")} type="button" className="btn btn-outline-primary hidden">Unlock Page</button>
                <button onClick={() => sendToCheckout("price_1L7TX2HqLsWsYyQwzh6UOlzW")} type="button" className="btn btn-outline-primary hidden">Checkout</button>
                <div class="mb-4 alert alert-primary hidden" role="alert">
                    Wooo! We did it! To celebrate 1,000 people signing up for Octie we just added a new A.I. for writing "Social Media Captions"! Thank you everyone for all your excitement and support! What A.I. should we add next? :)
                </div>
                <div class="mb-4 alert alert-primary hidden" role="alert">
                    Thank you everyone for all the love and support! Octie has been a passion project and it's been so fun to share it with all of you. Please feel free to share Octie with your friends and colleagues! Send them a link, or <a target="_blank" href="https://twitter.com/intent/tweet?text=This%20free%20A.I.%20tool%20will%20literally%20write%20your%20marketing%20emails,%20product%20descriptions,%20and%20more,%20in%20seconds,%20and%20it's%20REALLY%20good.%0A%0AI'm%20loving%20%40octieai%20from%20%40octaneai%20%26%20%40mattprd%20so%20far,%20it%20saves%20me%20a%20ton%20of%20time!%0A%0Ahttps%3A//octie.ai">click here to share on Twitter</a> <i class="bi bi-heart-fill"></i>
                </div>
                <div class="mb-4 alert alert-primary" role="alert">
                    Tip: Tired of writing your target customer and brand type into Octie tasks over and over? Save them to your brand settings and they will be prefilled!
                </div>
                <h3>Hello! What can I help you write today?</h3>

              </div>
            </div>
    
            <div className="mb-4">
              <button onClick={() => getTaskListAllCategory()} type="button" id="category_all" className="category_selector mb-2 btn btn_spaced btn-dark">All</button>
              <button onClick={() => getTaskListCategory("website copy", "website_copy")} type="button" id="category_website_copy" className="category_selector mb-2 btn btn_spaced btn-outline-secondary">Website Copy</button>
              <button onClick={() => getTaskListCategory("social media", "social_media")} type="button" id="category_social_media" className="category_selector mb-2 btn btn_spaced btn-outline-secondary">Social Media</button>
              <button onClick={() => getTaskListCategory("email / sms marketing", "email_sms_marketing")} type="button" id="category_email_sms_marketing" className="category_selector mb-2 btn btn_spaced btn-outline-secondary">Email / SMS Marketing</button>
              <button onClick={() => getTaskListCategory("influencer marketing", "influencer_marketing")} type="button" id="category_influencer_marketing" className="category_selector mb-2 btn btn_spaced btn-outline-secondary">Influencer Marketing</button>
              <button onClick={() => getTaskListCategory("customer support", "customer_support")} type="button" id="category_customer_support" className="category_selector mb-2 btn btn_spaced btn-outline-secondary">Customer Support</button>
              <button onClick={() => getTaskListCategory("misc", "misc")} type="button" id="category_misc" className="category_selector mb-2 btn btn_spaced btn-outline-secondary">Misc</button>
            </div>
            
    
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3" id="task_box">
                {tasks}
                <div className="col mb-4 d-flex align-items-stretch hidden">
                    <div className="card flex-fill task-card bg-dark text-white">
                        <div className="card-body flex-column d-flex">
                            <h5 className="card-title">MYSTERY A.I.</h5>
                            <p className="text-secondary">Octie is making something new...</p>
                            <button onClick={() => setSelectedSubPage("unlock")} type="button" className="btn btn-primary">HELP UNLOCK THIS NEW A.I.</button>
                        </div>
                    </div>
                </div>
                <div className="col mb-4 d-flex align-items-stretch hidden">
                    <div className="card flex-fill task-card bg-dark text-white">
                        <div className="card-body flex-column d-flex">
                            <h5 className="card-title">MYSTERY A.I. TASK #2</h5>
                            <p className="text-secondary">Octie is making something new...</p>
                            <button onClick={() => setSelectedSubPage("unlock")} type="button" className="btn btn-primary">HELP UNLOCK THIS NEW A.I.</button>
                        </div>
                    </div>
                </div>

                

            </div>
            <div class="col-sm-12 text-secondary">
                    <hr></hr>
                    <div className="pt-2 pb-2">Tip: Want to increase sales by marketing <i>even</i> better? Launch an <a href="https://octaneai.com" target="_blank">Octane AI</a> quiz.</div>
                </div>

          </div>
        </div>
    
    </>
    );} else if (selectedSubPage == "ideas") {
        return (
        <>
        
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark  fixed-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="#" onClick={SelectHomepage}>
                    <img src="octane_circle.png" alt="" width="30" height="30"/>
                </a>
                <a className="navbar-brand hidden" href="#">octie.ai</a>
        
                <button onClick={openTeamNav} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                <i class="bi bi-briefcase"></i> Teams
                </button>
            
                <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                    <i class="bi bi-box-arrow-right"></i> Sign out
                </button>

                <button className="navbar-toggler hidden" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                    <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                    <span className="text-warning hidden">Octie is built by Octane AI, the #1 quiz for Shopify brands</span>
                    </li>
                </ul>
                <div className="d-flex">
                    <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                        Sign out
                    </button>
                    <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                        Contact us
                    </a>
                    <span>-</span>
                    <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                        Share
                    </a>
                    
                </div>
                </div>
            </div>
            </nav>
        
        <main>
        <div className="d-flex flex-column flex-shrink-0 p-3 text-dark bg-white sidebar sidebar-mobile">
            
            <ul className="nav nav-pills flex-column mb-auto">
                {brand_profiles}
                <li>
                    <hr/>
                <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                    <i className ="me-2 bi-plus-circle"></i>
                    Create new team
                </a>
                </li>
                <li >
                    
                <a href="#" className="nav-link text-dark" onClick={sendToCustomerPortal}>
                    <i className ="me-2 bi-gear"></i>
                    Manage account
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-pencil-square"></i>
                    Request a feature
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-share"></i>
                    Share invites
                </a>
                </li>
                <li className="hidden">
                <a href="#" className="nav-link text-white">
                    <i className ="me-2 bi-graph-up-arrow"></i>
                    Upgrade your store
                </a>
                </li>
            </ul>
            <br></br>
            <div class="card bg-dark text-white">
                <img class="card-img opacity-25" src="jonesroad.png" alt="Card image"/>
                <div class="card-img-overlay">
                    <p class="card-text"><b>How The Fastest-Growing Shopify Store 3Xed Their TikTok Ad Revenue</b></p>
                    <a href="https://www.octaneai.com/customers/jones-road-beauty" target="_blank" type="button" class="btn btn-dark btn-sm stretched-link text-warning">Continue reading</a>
                </div>
            </div>
            </div>
        </main>
        
        <div className="main_content_right">
              <div className="main">
        
                <div className="main_hero">
                    <br></br>
                    <a href="#" onClick={() => SelectHomepage()} className="btn btn-primary button_top_right">+ Create content with AI</a>
                    <a href="#" onClick={() => setSelectedSubPage("new_team")} className="me-2 btn btn-dark button_top_right hidden">Test: New User Sign Up</a>
                    <h3>Drafts</h3>
                </div>
                
                <div className="row row-cols-1" id="ideas">
                {brand_ideas}
                </div>
                
                
                
                
                <div id="empty_feed_ideas" className="col mb-4 d-flex align-items-stretch hidden">
                    <div className="card flex-fill">
                    <div className="card-body flex-column d-flex text-secondary">
                        <center>
                        <br></br>
                        <h1 className="card-text result"><i class="bi bi-book-half"></i></h1>
                        <p className="card-text result">Create content with AI to create your first draft!</p>
                        <p className="card-text result">Drafts can only be seen by you.</p>
                        <br></br>
                        </center>
                    </div>
                    </div>
                </div>
        
              </div>
            </div>
        
        </>
        );} else if (selectedSubPage == "workspace") {
            return (
            <>
            
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark  fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#" onClick={SelectHomepage}>
                        <img src="octane_circle.png" alt="" width="30" height="30"/>
                    </a>
                    <a className="navbar-brand hidden" href="#">octie.ai</a>
            
                    <button onClick={openTeamNav} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                        <i class="bi bi-briefcase"></i> Teams
                    </button>
                    
                    <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                        <i class="bi bi-box-arrow-right"></i> Sign out
                    </button>

                    <button className="navbar-toggler hidden" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                        <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <button className="btn btn-outline-light hidden" type="submit">
                            Beta invites <span className="badge bg-danger">3</span>
                        </button>
                        <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                            Sign out
                        </button>
                        <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                            Contact us
                        </a>
                        <span>-</span>
                        <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                            Share
                        </a>
                    </div>
                    </div>
                </div>
                </nav>
            
            <main>
            <div className="d-flex flex-column flex-shrink-0 p-3 text-dark bg-white sidebar sidebar-mobile">
              
                
                <ul className="nav nav-pills flex-column mb-auto">
                    {brand_profiles}
                    <li>
                    <hr/>
                    <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                        <i className ="me-2 bi-plus-circle"></i>
                        Create new team
                    </a>
                    </li>
                    <li >
                    
                        <a href="#" className="nav-link text-dark" onClick={sendToCustomerPortal}>
                            <i className ="me-2 bi-gear"></i>
                            Manage account
                        </a>
                    </li>
                    <li className="hidden">
                    <a href="#" className="nav-link text-white">
                        <i className ="me-2 bi-chat-heart"></i>
                        Get to know me
                    </a>
                    </li>
                    <li className="hidden">
                    <a href="#" className="nav-link text-white">
                        <i className ="me-2 bi-pencil-square"></i>
                        Request a feature
                    </a>
                    </li>
                    <li className="hidden">
                    <a href="#" className="nav-link text-white">
                        <i className ="me-2 bi-share"></i>
                        Share invites
                    </a>
                    </li>
                    <li className="hidden">
                    <a href="#" className="nav-link text-white">
                        <i className ="me-2 bi-graph-up-arrow"></i>
                        Upgrade your store
                    </a>
                    </li>
                </ul>
                <br></br>
                <div class="card bg-dark text-white">
                    <img class="card-img opacity-25" src="jonesroad.png" alt="Card image"/>
                    <div class="card-img-overlay">
                        <p class="card-text"><b>How The Fastest-Growing Shopify Store 3Xed Their TikTok Ad Revenue</b></p>
                        <a href="https://www.octaneai.com/customers/jones-road-beauty" target="_blank" type="button" class="btn btn-dark btn-sm stretched-link text-warning">Continue reading</a>
                    </div>
                </div>
        
                </div>
            </main>
            
            <div className="main_content_right">
                  <div className="main">
            
                    <div className="main_hero">
                        <br></br>
                        <a href="#" onClick={() => SelectHomepage()} className="btn btn-primary btn_rounded button_top_right">+ Create content with AI</a>
                        <h3>Workspace</h3>
                    </div>
                    
                    <div className="row row-cols-1" id="feed_workspace">
                    
                    </div>
                    {brand_workspace}
                    <div id="empty_feed_workspace" className="col mb-4 d-flex align-items-stretch hidden">
                    <div className="card flex-fill">
                    <div className="card-body flex-column d-flex text-secondary">
                        <center>
                        <br></br>
                        <h1 className="card-text result"><i class="bi bi-lightning-charge"></i></h1>
                        <p className="card-text result">Create content with AI, or move it from drafts, to share your first content in your team's workspace!</p>
                        <p className="card-text result">Everyone on your team can see the content in your workspace.</p>
                        <br></br>
                        </center>
                    </div>
                    </div>
                </div>
                    <input type="hidden" id="temp_name_space"/>
                  </div>
                </div>
            
            </>
            );} else if (selectedSubPage == "team") {
                return (
                <>
                
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark  fixed-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" onClick={SelectHomepage}>
                            <img src="octane_circle.png" alt="" width="30" height="30"/>
                        </a>
                        <a className="navbar-brand hidden" href="#">octie.ai</a>
                
                        <button onClick={openTeamNav} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                            <i class="bi bi-briefcase"></i> Teams
                        </button>
                        
                        <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary mobile" type="submit">
                            <i class="bi bi-box-arrow-right"></i> Sign out
                        </button>

                        <button className="navbar-toggler hidden" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                            <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                            </li>
                        </ul>
                        <div className="d-flex">
                            <button className="btn btn-outline-light hidden" type="submit">
                                Beta invites <span className="badge bg-danger">3</span>
                            </button>
                            <button onClick={signOutNow} className="me-2 btn btn-link text-decoration-none text-secondary" type="submit">
                                Sign out
                            </button>
                            <a href="https://forms.gle/5EBWQJuYh76x1rkW9" target="_blank" className="btn btn-outline-light" type="submit">
                                Contact us
                            </a>
                            <span>-</span>
                            <a href="#" target="_blank" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shareModal">
                                Share
                            </a>
                        </div>
                        </div>
                    </div>
                    </nav>
                
                <main>
                <div className="d-flex flex-column flex-shrink-0 p-3 text-dark bg-white sidebar sidebar-mobile">
                    
                    <ul className="nav nav-pills flex-column mb-auto">
                        {brand_profiles}
                        <li>
                            <hr/>
                        <a href="#" className="nav-link text-dark" onClick={() => createNewTeam()}>
                            <i className ="me-2 bi-plus-circle"></i>
                            Create new team
                        </a>
                        </li>
                        <li>
                    
                            <a href="#" className="nav-link text-dark" onClick={sendToCustomerPortal}>
                                <i className ="me-2 bi-gear"></i>
                                Manage account
                            </a>
                        </li>
                        <li className="hidden">
                        <a href="#" className="nav-link text-white">
                            <i className ="me-2 bi-chat-heart"></i>
                            Get to know me
                        </a>
                        </li>
                        <li className="hidden">
                        <a href="#" className="nav-link text-white">
                            <i className ="me-2 bi-pencil-square"></i>
                            Request a feature
                        </a>
                        </li>
                        <li className="hidden">
                        <a href="#" className="nav-link text-white">
                            <i className ="me-2 bi-share"></i>
                            Share invites
                        </a>
                        </li>
                        <li className="hidden">
                        <a href="#" className="nav-link text-white">
                            <i className ="me-2 bi-graph-up-arrow"></i>
                            Upgrade your store
                        </a>
                        </li>
                    </ul>
                    <br></br>
                    <div class="card bg-dark text-white">
                        <img class="card-img opacity-25" src="jonesroad.png" alt="Card image"/>
                        <div class="card-img-overlay">
                            <p class="card-text"><b>How The Fastest-Growing Shopify Store 3Xed Their TikTok Ad Revenue</b></p>
                            <a href="https://www.octaneai.com/customers/jones-road-beauty" target="_blank" type="button" class="btn btn-dark btn-sm stretched-link text-warning">Continue reading</a>
                        </div>
                    </div>
            
                    </div>
                </main>
                
                <div className="main_content_right">
                      <div className="main">
                
                        <div className="main_hero">
                            <br></br>
                            <h3>Team</h3>
                        </div>

                        

                        <div id="empty_feed_workspace" className="col mb-4 d-flex align-items-stretch">
                            <div className="card flex-fill">
                                <div className="card-header">Invite your teammates</div>
                                <div className="card-body flex-column d-flex">
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" id="email_invite_recipient" placeholder="Email address..." aria-label="Recipient's email" aria-describedby="button-addon2" />
                                        <button onClick={checkIfUserAlreadyInvited} class="btn btn-primary" type="button" id="button-addon2"><i class="me-2 bi bi-send"></i> Send invite</button>
                                    </div>
                                    <p className="card-text text-secondary hidden">You have 3 invites left</p>
                                </div>
                            </div>
                        </div>

                        <ul className="list-group">
                            {brand_team}
                        </ul>

                        <input type="hidden" id="temp_name_space" />
                        <input type="hidden" id="hidden_brand_id" />
                        <input type="hidden" id="hidden_brand_name" />
                        <input type="hidden" id="hidden_user_name" />

                      </div>
                    </div>
                
                </>
                );} else if (selectedSubPage == "new_user_signup_intro") {
                return (
                <>
                
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                        <img src="octane_circle.png" alt="" width="30" height="30"/>
                        </a>
                        <a className="navbar-brand hidden" href="#">octie.ai</a>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item hidden">
                            <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                            </li>
                        </ul>
                        <div className="d-flex">
                            <button className="btn btn-outline-light hidden" type="submit">
                                Beta invites <span className="badge bg-danger">3</span>
                            </button>
                            <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary" type="submit">
                                Sign out
                            </button>
                    
                        </div>
                        </div>
                    </div>
                    </nav>



                    <div className="main_content container d-flex h-75 hidden" id="new_user_intro_to_octie">
                        <div className="main main_signup row align-self-center">
                            <br></br>

                            <div id="new_user_1" className="align-self-center text-center"> 
                                <img src="octie.png" width="75px"></img>
                                <br></br>
                                <br></br>
                                <div className="text-center">
                                    <h3>Hi there! I'm Octie! I'm an AI made by Octane AI that can help you a TON with writing marketing content for your brand.</h3>
                                </div>
                                <br></br>
                                <button onClick={() => answerQuizQuestion("new_user_1", "new_user_2")} type="button" class="me-2 btn btn-outline-dark btn-lg">Hello Octie!</button>
                                <button onClick={() => answerQuizQuestion("new_user_1", "new_user_2")} type="button" class=" btn btn-outline-dark btn-lg">Nice to meet you!</button>
                            </div>

                            <div id="new_user_2" className="align-self-center text-center hidden"> 
                                <img src="octie.png" width="75px"></img>
                                <br></br>
                                <br></br>
                                <div className="text-center">
                                    <h3>So good to meet you! I'm going to help you write text messages, ads, emails, and more! I've read over 10% of the ENTIRE INTERNET so I'm pretty creative :)</h3>
                                </div>
                                <br></br>
                                <button onClick={() => answerQuizQuestion("new_user_2", "new_user_3")} type="button" class="me-2 btn btn-outline-dark btn-lg quiz_buttons">AMAZING!</button>
                                <button onClick={() => answerQuizQuestion("new_user_2", "new_user_3")} type="button" class="me-2 btn btn-outline-dark btn-lg quiz_buttons">I want to try it out!</button>
                            </div>

                            <div id="new_user_3" className="align-self-center text-center hidden"> 
                                <img src="octie.png" width="75px"></img>
                                <br></br>
                                <br></br>
                                <div className="text-center">
                                    <h3>BOOM! Let's DO THIS! Ready to get started?</h3>
                                </div>
                                <br></br>
                                <button onClick={() => answerQuizQuestion("new_user_3", "final")} type="button" class="me-2 btn btn-outline-dark btn-lg quiz_buttons">Let's get started!</button>
                                <button onClick={() => answerQuizQuestion("new_user_3", "final")} type="button" class="me-2 btn btn-outline-dark btn-lg quiz_buttons">I'm so ready</button>
                                <button onClick={() => answerQuizQuestion("new_user_3", "final")} type="button" class="me-2 btn btn-outline-dark btn-lg quiz_buttons">I'm ready to use AI!!!</button>
                            </div>

                        </div>
                    </div>

                    <div className="main_content container hidden" id="new_user_application">
                        <div className="main main_signup">
                            <div className=""> 
                                <div className="text-center">
                                    <br></br>
                                    <h1>Tell me about yourself!</h1>
                                </div>
                                <br></br>
                                <div class="card">
                                    <div class="card-body">
                                        <form className="row">
                                            <div class="mb-3">
                                                <label class="form-label">Company name:</label>
                                                <input type="text" className="form-control task_input" id="application_brand_name" placeholder="Example: Jones Road" required></input>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Website:</label>
                                                <input type="text" className="form-control task_input" id="application_brand_website" placeholder="Example: jonesroadbeauty.com"></input>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Do you work with Shopify:</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="shopify_status" id="exampleRadios1" value="I am a merchant who uses Shopify" />
                                                    <label class="form-check-label" for="exampleRadios1">
                                                        I am a merchant who uses Shopify
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="shopify_status" id="exampleRadios2" value="I am an agency who works with Shopify merchants" />
                                                    <label class="form-check-label" for="exampleRadios2">
                                                        I am an agency who works with Shopify merchants
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="shopify_status" id="exampleRadios3" value="I am a tech company who works with Shopify merchants" />
                                                    <label class="form-check-label" for="exampleRadios3">
                                                        I am a tech company who works with Shopify merchants
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="shopify_status" id="exampleRadios4" value="I do not work with or use Shopify right now" />
                                                    <label class="form-check-label" for="exampleRadios4">
                                                        I do not work with or use Shopify right now
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Why do you want to use Octie:</label>
                                                <input type="text" className="form-control task_input" id="application_note" placeholder="We'd love to know!"></input>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Where did you hear about Octie:</label>
                                                <input type="text" className="form-control task_input" id="application_source" placeholder="Was it in a dream...?"></input>
                                            </div>
                                        
                                        </form>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <br></br>
                                    <a href="#" onClick={checkForCodes} className="btn btn-primary btn_rounded">Continue</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="main_content container d-flex h-75 hidden" id="new_user_waitlist">
                        <div className="main main_signup row align-self-center">
                            <br></br>

                            <div className="align-self-center text-center"> 
                                <img src="octie.png" width="75px"></img>
                                <br></br>
                                <br></br>
                                <div className="text-center">
                                    <h3>You are on the waitlist!</h3>
                                    <br></br>
                                    <p>We are letting new people in every day, we will email you when it is your turn.</p>
                                    <p>In the mean time, setup a product recommendation quiz for your Shopify store with <a target="_blank" href="https://octaneai.com">Octane AI</a>.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
    );} else if (selectedSubPage == "subscribe") {
        return (
        <>
        
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                <img src="octane_circle.png" alt="" width="30" height="30"/>
                </a>
                <a className="navbar-brand hidden" href="#">octie.ai</a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item hidden">
                    <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                    </li>
                </ul>
                <div className="d-flex">
                    <button className="btn btn-outline-light hidden" type="submit">
                        Beta invites <span className="badge bg-danger">3</span>
                    </button>
                    <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary" type="submit">
                        Sign out
                    </button>
            
                </div>
                </div>
            </div>
            </nav>

            <div id="stripe_loading" class="stripe_loading hidden">
                <div class="spinner-grow" role="status">
                    <span class="sr-only"></span>
                </div>
                <br></br>
                <br></br>
                <div>One moment while we redirect you</div>
            </div>

            <div className="main_content container">
                <div className="main main_signup">
                    <div className=""> 
                        <div className="text-center">
                            <br></br>
                            <h1>Octie is going to blow your mind. You've never seen anything like it.</h1>
                            <p className="hidden">Your AI powered ecommerce marketing assistant.</p>
                        </div>
                        <br></br>
                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 d-flex align-items-stretch">
                        
                            <div className="col mb-12">
                                <div class="card text-center">
                                    <div class="card-header hidden">
                                        Monthly
                                    </div>
                                    <div class="card-body">
                                        <br></br>
                                        <h3 class="card-title">$25/month</h3>
                                        <p class="card-text">Use AI to write marketing copy 10x faster and generate any image you need.<br></br>Full emails, blog post ideas, product descriptions, and more.<br></br>It's like magic.</p>
                                        <a onClick={() => sendToCheckout("price_1L7TX2HqLsWsYyQwzh6UOlzW")} href="#" class="btn btn-primary"><b>START YOUR FREE TRIAL</b></a>
                                        <br></br><br></br>
                                        <p className="text-secondary">Cancel any time</p>
                                        <br></br>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                            <div className="col mb-6 hidden">
                                <div class="card text-center">
                                    <div class="card-header">
                                        Annual <span class="badge bg-secondary">SAVE 25%</span>
                                    </div>
                                    <div class="card-body">
                                        <br></br>
                                        <h1 class="card-title">$225/year</h1>
                                        <p class="card-text">Use AI for all your ecommerce marketing needs.</p>
                                        <a onClick={() => sendToCheckout("price_1L9F6qHqLsWsYyQwHCCED8wD")} href="#" class="btn btn-primary">Choose plan</a>
                                        <br></br><br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br><br></br>
                    </div>
                </div>
            </div>
            
        </>
    );} else if (selectedSubPage == "unlock") {
        return (
        <>
        
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                <img src="octane_circle.png" alt="" width="30" height="30"/>
                </a>
                <a className="navbar-brand hidden" href="#">octie.ai</a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item hidden">
                    <a href="#" onClick={SelectHomepage} className="nav-link" aria-current="page"><b>Octie</b> by Octane AI</a>
                    </li>
                </ul>
                <div className="d-flex">
                    <button className="btn btn-outline-light hidden" type="submit">
                        Beta invites <span className="badge bg-danger">3</span>
                    </button>
                    <button onClick={signOutNow} className="btn btn-link text-decoration-none text-secondary" type="submit">
                        Sign out
                    </button>
            
                </div>
                </div>
            </div>
            </nav>

            <div id="stripe_loading" class="stripe_loading hidden">
                <div class="spinner-grow" role="status">
                    <span class="sr-only"></span>
                </div>
                <br></br>
                <br></br>
                <div>One moment while we redirect you</div>
            </div>

            <div className="unlock">
            <div className="main_content container">
                <div className="main main_signup">
                    <div className=""> 
                        <div className="text-center">
                            <br></br>
                            <a onClick={() => setSelectedSubPage("homepage")} href="#" class="btn btn-outline-dark">Back to Octie</a>
                            <br></br><br></br>
                            <h1><b>Octie has created a new A.I. task in the secret science lab.</b> Work together to unlock it for everyone!</h1>
                            <p className="hidden">Your AI powered ecommerce marketing assistant.</p>
                        </div>
                        <br></br>
                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 d-flex align-items-stretch">
                        
                            <div className="col mb-12">
                                <div class="card text-center">
                                    <div class="card-header hidden">
                                        Monthly
                                    </div>
                                    <div class="card-body">
                                        <br></br>
                                        <h3 class="card-title">Once 1,000 people have signed up for Octie, Octie has promised to release the new A.I. tasks for free.</h3>
                                        <br></br>
                                        <div class="progress progress-unlock">
                                            <div class="progress-bar progress-unlock progress-progress" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100">978 / 1,000 people</div>
                                        </div>
                                        <br></br>
                                        <h3 class="card-title">Share Octie to help unlock new A.I.</h3>
                                        <br></br>
                                        <a target="_blank" href="https://twitter.com/intent/tweet?text=Dear%20anyone%20in%20ecommerce%3A%20Sign%20up%20for%20this%20asap!%0A%0AOctie.ai%20(from%20%40OctaneAI)%20is%20an%20A.I.%20that%20writes%20your%20emails,%20SMS,%20%26%20more,%20for%20you!%20It's%20100%25%20FREE%20and%20it's%20REALLY%20GOOD.%0A%0AOnce%201,000%20people%20sign%20up%20%40octieai%20will%20be%20releasing%20AN%20EXTRA%20A.I.%20that%20we%20can%20all%20use%20%F0%9F%9A%80" class="btn btn-lg btn-primary">SHARE ON <b>TWITTER</b></a>
                                        <br></br>
                                        <br></br>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                            <div className="col mb-6 hidden">
                                <div class="card text-center">
                                    <div class="card-header">
                                        Annual <span class="badge bg-secondary">SAVE 25%</span>
                                    </div>
                                    <div class="card-body">
                                        <br></br>
                                        <h1 class="card-title">$225/year</h1>
                                        <p class="card-text">Use AI for all your ecommerce marketing needs.</p>
                                        <a onClick={() => sendToCheckout("price_1L9F6qHqLsWsYyQwHCCED8wD")} href="#" class="btn btn-primary">Choose plan</a>
                                        <br></br><br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br><br></br>
                    </div>
                </div>
            </div>
            </div>
            
        </>
    );}
}
