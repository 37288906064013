import React, {useEffect, useState} from 'react'
import GetTask_UI from './UI';
import { cloudFunctions } from '../utils/firebase';
import { db } from '../utils/firebase';
import { collection, getDocs, getDoc, doc, setDoc, query, where } from 'firebase/firestore';


export default function GetTask({
    the_userid, taskid, the_brandid
}) {

        const [task_name, setTaskname] = useState("");
        const [task_inputs, setTaskninputs] = useState("");
        const [task_input_types, setTaskinput_types] = useState("");
        const [task_input_placeholders, setTaskinput_placeholders] = useState("");
        const [task_prompt, setTaskprompt] = useState("");
        const [task_temperature, setTasktemperature] = useState("");
        const [task_max_tokens, setTaskmax_tokens] = useState("");
        const [task_type, setTask_type] = useState("");

        useEffect(() => {
            getTask();
            getCloud_selected_brand_profile();
        },[])
        const getTask = async() => {
            
        const docRef = doc(db, "tasks", taskid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {

            setTaskname(docSnap.data().name);
            setTaskninputs(docSnap.data().inputs);
            setTaskinput_types(docSnap.data().input_types);
            setTaskinput_placeholders(docSnap.data().input_placeholders);
            setTaskprompt(docSnap.data().prompt);
            setTasktemperature(docSnap.data().temperature);
            setTaskmax_tokens(docSnap.data().max_tokens);
            setTask_type(docSnap.data().task_type);

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

      }

       // Generate Brand Profile Dropdown
       
       const [brand_profiles, setBrand_profiles] = useState('');

       const brand_profiles_arr = [];


       const getBrandProfiles = async(cloud_brandprofile_id) => {
           const q = query(collection(db, "brand_profiles"), where("roles." + the_userid,"in", ["owner", "writer", "reader"]));
           const querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
               // doc.data() is never undefined for query doc snapshots
               //console.log(doc.id, " => ", doc.data());
               
               //setTasks([...tasks, doc.data()]);
               //setTasks([...tasks, {name: doc.data().name}]);
               console.log(doc.data());
               if (cloud_brandprofile_id === doc.id){
                    brand_profiles_arr.push({cloudselected: "yes", id: doc.id, name: doc.data().name, type: doc.data().type, target_customer: doc.data().target_customer, values: doc.data().values, website: doc.data().website});
               } else {
                    brand_profiles_arr.push({cloudselected: "no", id: doc.id, name: doc.data().name, type: doc.data().type, target_customer: doc.data().target_customer, values: doc.data().values, website: doc.data().website});
               }
               
           });
           console.log("Brand profiles: " + brand_profiles_arr);

           //Pull in the id for the currently selected brand profile and set that to be selected

           // MAKE A FUNCTION THAT AUTO GENERATES BASED ON THE TASK ID
           const final_brand_profiles_arr = brand_profiles_arr.map((brand_profile) =>
               <>
               <option key={brand_profile.id} id={brand_profile.id} value={brand_profile.id} selected={brand_profile.cloudselected === 'yes'}>{brand_profile.name}</option>
               </>
           );
           setBrand_profiles(final_brand_profiles_arr);
           getBrandProfileSpecific(cloud_brandprofile_id);
       }

       // END Generate Brand Profile Dropdown

       const [selected_brand_profiles, setSelected_brand_profiles] = useState('');

       function setDefaultBrandProfile(){
         const selectBrand = document.querySelector('.form-select');
         const selectedBrand = selectBrand.options[selectBrand.selectedIndex].value;
         setSelected_brand_profiles(selectedBrand);
         getBrandProfileSpecific(selectedBrand);
         updateCloudSelectedBrandProfile(selectedBrand);
       }

       // Get specific brand profile info

       const [brand_name, setBrand_name] = useState('');
       const [brand_type, setBrand_type] = useState('');
       const [brand_values, setBrand_values] = useState('');
       const [brand_target_customer, setBrand_target_customer] = useState('');

       const getBrandProfileSpecific = async(testtest) => {

        const docRef = doc(db, "brand_profiles", testtest);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {

            setBrand_name(docSnap.data().name);
            setBrand_type(docSnap.data().type);
            setBrand_values(docSnap.data().values);
            setBrand_target_customer(docSnap.data().target_customer);

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

        }

        // END Get specific brand profile info

        // Get last selected brand profile ID from cloud

        const [cloud_selected_brand_profile, setCloud_selected_brand_profile] = useState('');

        const getCloud_selected_brand_profile = async() => {

            const docRef = doc(db, "users", the_userid);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {

                setCloud_selected_brand_profile(docSnap.data().selected_brand_profile);
                //getBrandProfiles(docSnap.data().selected_brand_profile);
                getBrandProfileSpecific(docSnap.data().selected_brand_profile);

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

        }

        // END Get last selected brand profile ID from cloud

        // Update selected brand profile ID in the cloud

        const updateCloudSelectedBrandProfile = async(cloudbrandid) => {

            const cloudbrandprofileRef = doc(db, 'users', the_userid);
            setDoc(cloudbrandprofileRef, { 
                selected_brand_profile: cloudbrandid,
            }, { merge: true });
         
        }

        // END Update selected brand profile ID in the cloud


    return (
        <>
          
        
                <div className="main_hero">
                <div>
                <br></br>
                    <h3>{task_name}</h3>
                </div>
                </div>
                <hr/>
                
                <br></br>
                <GetTask_UI brand_name={brand_name} brand_type={brand_type} brand_values={brand_values} brand_target_customer={brand_target_customer} name={task_name} inputs={task_inputs} input_types={task_input_types} input_placeholders={task_input_placeholders} prompt={task_prompt} temperature={task_temperature} max_tokens={task_max_tokens} the_userid={the_userid} the_brandid={the_brandid} taskid={taskid} task_type={task_type}/>
          

        </>
    )

}

