import React, {useEffect,useState} from 'react'
import { cloudFunctions } from '../utils/firebase';
import { db } from '../utils/firebase';
import { collection, getDocs, getDoc, doc, setDoc } from 'firebase/firestore';


export default function GetBrandProfile({
    brandprofileid
}) {

        const [brandprofile_name, setBrandprofile_name] = useState("");

        useEffect(() => {
            getBrandProfileSpecific();
        },[])
        const getBrandProfileSpecific = async() => {

        const docRef = doc(db, "brand_profiles", brandprofileid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {

            document.getElementById('brandprofile_name').value = docSnap.data().name;
            document.getElementById('brandprofile_website').value = docSnap.data().website;
            document.getElementById('brandprofile_type').value = docSnap.data().type;
            document.getElementById('brandprofile_values').value = docSnap.data().values;
            document.getElementById('brandprofile_target_customer').value = docSnap.data().target_customer;

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

        }
      // Update brand profile data
        
        const updateBrandProfile = async() => {

            
            const brandprofileRef = doc(db, 'brand_profiles', brandprofileid);
            setDoc(brandprofileRef, { 
                name: document.getElementById('brandprofile_name').value,
                website: document.getElementById('brandprofile_website').value,
                type: document.getElementById('brandprofile_type').value,
                values: document.getElementById('brandprofile_values').value,
                target_customer: document.getElementById('brandprofile_target_customer').value
                
            }, { merge: true });

            
        }

      // END Update brand profile data

    return (
        <>
                
                <form>
                    <div class="mb-3">
                        <label class="form-label">Brand name:</label>
                        <input type="text" className="form-control task_input" id="brandprofile_name" placeholder="Example: Jones Road"></input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Website:</label>
                        <input type="text" className="form-control task_input" id="brandprofile_website" placeholder="Example: jonesroadbeauty.com"></input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Brand type:</label>
                        <input type="text" className="form-control task_input" id="brandprofile_type" placeholder="Example: Clean makeup"></input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Brand values:</label>
                        <input type="text" className="form-control task_input" id="brandprofile_values" placeholder="Example: Clean, strategic, high-grade formulations that work on every skin type"></input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Target customer:</label>
                        <input type="text" className="form-control task_input" id="brandprofile_target_customer" placeholder="Example: Women of all ages"></input>
                    </div>
                </form>
                <a href="#" onClick={updateBrandProfile} className="btn btn-primary btn_rounded">Save</a>


        </>
    )

}

